import { Controller } from "@hotwired/stimulus"
import { decorateButtons, validateDateFieldsFormat } from "../original_easykeeper/inputs"

// Connects to data-controller="bulk-milk-records"
export default class extends Controller {
  static values = {
    url: String
  }

  connect() {

  }

  //
  // controller change action in /app/views/bulk_data/milk_records.html.haml
  // calls bulk-milk-record#bulkDateSelectChange
  // on 'change' event, via dom or date_picker_controller since jqueryUi
  // datepicker does not trigger regular dom 'change' event
  //
  bulkDateSelectChange() {
    let $element        = $(this.element)
    let $form           = $element.closest('form')
    let $continueButton = $form.find('.bulk_milk_continue')
    let $dateField      = $form.find(".date_field")

    if(validateDateFieldsFormat($form)) {
      if($dateField.val() == "") {
        window.App.flash(window.App.empty_date_error);
        window.App.clearBulkMilkRecords()
      } else {
        if(!this.hasUrlValue) {
          alert("Date change url not found")
        }

        $.ajax({
          url: this.urlValue,
          dataType: "script",
          data: {
            date: $dateField.val()
          },
          beforeSend: () => {
            $continueButton.addClass('waiting')
          },
          error: function() {
            $continueButton.removeClass('waiting');
            window.App.flash(window.App.invalid_date_error);
          },
          complete: ()=> {
            decorateButtons()
            $continueButton.removeClass('waiting');
          }
        });
      }
    }
  }

  completedOnChangedToggleNotes() {
    let $dateInput = $(this.element);
    let $notes = $dateInput.closest('td').find('.notes');

    if ($dateInput.val() == '') {
      $notes.addClass('hidden');
    } else {
      $notes.removeClass('hidden');
    }
  }
}
