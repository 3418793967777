import { Controller } from "@hotwired/stimulus"
import { checkForBlankCategory, clearFormErrors,
         eagerValidations, healthEventTemplateFieldsValidations } from "../original_easykeeper/batch_entry"
import { decorateButtons } from '../original_easykeeper/inputs'
import { loadingBar } from "../original_easykeeper/dialogs"

// Connects to data-controller="event-categories"
export default class extends Controller {
  connect() {
    eagerValidations()
  }

  change(event) {
    clearFormErrors()

    let $categorySelectField = $(event.target);
    let selectedCategory     = $categorySelectField.val();

    $.ajax({
      url:      event.params.fieldsUrl,
      dataType: "script",
      type:     "post",
      data: {
        category: selectedCategory
      },
      beforeSend: () => {
        $(".vrowgroup.activity").html(loadingBar());
      },
      // eslint-disable-next-line no-unused-vars
      error: (_event, _jqxhr, _settings, _thrownError) => {
        alert('We encountered an error fetching the form, please try again later')
      },
      success: () => {
        let $healthTemplateFields = $(`.health-event-or-treatment-reminder-fields`);

        if($categorySelectField.valid())
          $('.waiting').removeClass('waiting')

        decorateButtons()
        eagerValidations()
        // Run validations
        healthEventTemplateFieldsValidations($healthTemplateFields)
        checkForBlankCategory()
      }
    });
  }
}
