function resetAnimalList() {
  console.debug('resetAnimalList()')

  $("#animal_list_herd_title").removeClass("hidden");
  $("#animal_list_search_title").addClass("hidden");
  $("#animal_list li").each(function () {
    var $this = $(this);

    if ($this.attr("active") == "true") {
      $this.removeClass("hidden");
    } else {
      $this.addClass("hidden");
    }
  });
}

$(function () {
  $("input#animal_search_filter_field").on("focus", function () {
      console.debug('$("input#animal_search_filter_field").on("focus", function ()')

      if (this.value == "Search animals") {
        this.value = "";
        $(this).addClass("activeSearch");
      }
    })
    .on("blur", function () {
      console.debug('$("input#animal_search_filter_field").on("blur", function ()')

      if (this.value == "") {
        this.value = "Search animals";
        $(this).removeClass("activeSearch");
      }
    })
    .on("keyup", function () {
      console.debug('$("input#animal_search_filter_field").on("keyup", function ()')

      var s = $(this).val().toLowerCase();

      if (s == "" || s == "search animals") {
        resetAnimalList();
      } else {
        $("#animal_list_herd_title").addClass("hidden");
        $("#animal_list_search_title").removeClass("hidden");
        $("#animal_list li").each(function () {
          var $this = $(this);

          if (
            $this.attr("nickname").toLowerCase().indexOf(s) >= 0 ||
            $this.find("a").attr("title").toLowerCase().indexOf(s) >= 0
          ) {
            $this.removeClass("hidden");
          } else {
            $this.addClass("hidden");
          }
        });
      }
    });
});
