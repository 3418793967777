import { Controller } from "@hotwired/stimulus"
import { checkForBlankCategory, clearFormErrors,
         eagerValidations, healthEventTemplateFieldsValidations } from "../original_easykeeper/batch_entry"
import { decorateButtons } from '../original_easykeeper/inputs'
import { debug } from "./application"
import { loadingBar } from "../original_easykeeper/dialogs"

// Connects to data-controller="health-event-template-category"
export default class extends Controller {
  // Controller should only be used on Step 1 of Wizard
  // URL: /bulk_data/new_health_events
  // Step 1. Create Health Event Templates
  connect() {
    // jquery validations adds a novalidate attribute
    // we only want to initialize the validator once
    if(!$('#bulkHealthEventTemplateList')[0].hasAttribute('novalidate')) {
      console.log("VALIDATOR attached to #bulkHealthEventTemplateList")

      // validate() defaults set in application.js with $.validator.setDefaults()
      $("#bulkHealthEventTemplateList").validate({});
    }

    eagerValidations()
    checkForBlankCategory()
  }

  // When event category selection happens inject appropriate form elements
  //
  change(event) {
    clearFormErrors()

    let $categorySelectField = $(event.target);
    let selectedCategory     = $categorySelectField.val();
    let form_uid             = $categorySelectField.attr('event_index');
    let $batchEventForm      = $(`#batchEventForm_${form_uid}`);

    if(debug && event.params.fieldsUrl !== '/bulk_data/health_event_fields')
      alert("event.params.fieldsUrl is incorrect")

    $.ajax({
      url: event.params.fieldsUrl,
      dataType: "script",
      type: 'get',
      data: {
        category: selectedCategory,
        id: form_uid
      },
      beforeSend: () => {
        $batchEventForm.html(loadingBar());
        $(`#error_list_${form_uid}`).empty();
      },
      error: (event, jqxhr, settings, thrownError) => {
        alert('We encountered an error fetching the form, please try again later')
      },
      success: () => {
        let $healthTemplateFields = $(`#healthTemplateFields_${form_uid}`);

        if($categorySelectField.valid())
          $('.waiting').removeClass('.waiting')

        decorateButtons()
        eagerValidations()
        // Run validations
        healthEventTemplateFieldsValidations($healthTemplateFields)
        checkForBlankCategory();
      }
    });
  }
}
