import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="help-text"
export default class extends Controller {
  connect() {
  }

  toggleHidden() {
    $(this.element).closest(".error_help_text")
                   .find(".help_text_content")
                   .toggleClass('hidden');
  }
}
