import { Controller } from "@hotwired/stimulus"
import 'jquery-ui/ui/widgets/datepicker'
import { formFieldOK } from "../original_easykeeper/functions"
import { validateDateFieldsFormat, validateSingleDateFieldFormat } from "../original_easykeeper/inputs"

// Connects to data-controller="date-picker"
export default class extends Controller {
  connect() {
    // lactations_completed_on OR
    let $field = $(this.element);

    // highlight inputs that are connected to during debug
    // if($field.prop('tagName') == 'INPUT') {
    //   $field.css('border', '2px solid red')
    // }

    // top date field
    // if($field.hasClass('date_field')) {
    //   console.debug('this is a date_field')

    // } else {
    //   // lactations_completed_on dom element
    //   var field  = $field[0]
    // }

    // if (!$field.is(":disabled")) {
      $field.datepicker({
        shortYearCutoff: "+10",
        onSelect: function (dateText, datePickerInstance) {
          let $form = $field.closest('form')

          // validateSingleDateFieldFormat($field)

          let oldValue = $(this).data('oldValue') || "";

          // If val (date) in field actually changed manually
          // fire change event.  Work around for jQuery UI calendar
          // seemingly eating change events on attached field. CI 12/5/23

          if (dateText !== oldValue) {
            $(this).data('oldValue', dateText);

            const event = new Event("change", { bubbles: true });
            this.dispatchEvent(event)
          }
        }
      });
    // }
  }

  checkChangedDate(event) {
    console.debug("date-picker#checkChangedDate started")

    let $dateField = $(event.target)

    validateSingleDateFieldFormat($dateField)
  }
}
