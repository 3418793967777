import { ezkParseTime, ezkParseDate } from "./inputs";
import 'jquery-ui/ui/widgets/dialog'

var lastNickname     = "";
var nicknameTimeout  = null;
var curNicknameField = null;

$(function () {
  console.debug("breedings.js PAGELOAD callback()")

  $(".breeding_service_date")
    .filter(() => this.value == "")
    .closest(".vtable")
    .find(".vrowgroup.service_date_attributes")
    .addClass('hidden');

  rememberResultType();
  hideOutcomeFields();

  $('input:radio[name="breeding[result_type]"]:checked').trigger("change");
});

function maxServiceDate() {
  console.debug('maxServiceDate()')

  var serviceDates = $("#breeding_services input.breeding_service_date").map(
    function () {
      return ezkParseTime(this.value);
    }
  );

  var serviceDate = serviceDates.max();

  if (serviceDate && serviceDate != 0 && serviceDate != -Infinity) {
    return serviceDate;
  } else {
    return null;
  }
}

export function latestMethodology() {
  console.debug('latestMethodology()')

  var latestServiceDate = maxServiceDate();

  if (latestServiceDate != null && latestServiceDate != 0) {
    var latestDateInput = $("#breeding_services input.breeding_service_date")
      .filter(function () {
        return ezkParseTime(this.value) == latestServiceDate;
      })
      .first();

    if (latestDateInput.length > 0) {
      return latestDateInput
        .closest(".vtable")
        .find(".input.breeding_breeding_services_methodology select")
        .first()
        .val();
    } else {
      return "";
    }
  } else {
    return $(
      "#breeding_services .input.breeding_breeding_services_methodology select"
    )
      .last()
      .val();
  }
}

// Closure prevents duplicate dialogs
var updateGestation = (function () {
  console.debug("updateGestation()")

  var in_handler = false;

  return function () {
    var resultDate = null;

    if ($("#breeding_result_on").val() != "") {
      resultDate = ezkParseDate($("#breeding_result_on").val());
    } else {
      return;
    }

    var serviceDate = maxServiceDate();

    if (serviceDate != null) {
      var gestationDays = Math.round(
        (resultDate.getTime() - serviceDate) / (24 * 60 * 60 * 1000)
      );
      gestationDays = gestationDays.toString();

      var oldGestation = $("#breeding_gestation").val();

      if (oldGestation == null || oldGestation == "") {
        $("#breeding_gestation").val(gestationDays);
        $("#gestation_row .help_text").removeClass("hidden");
      } else if (oldGestation != gestationDays) {
        if (in_handler == false) {
          in_handler = true;

          $("<div />")
            .text("Update gestation based on this date?")
            .dialog({
              resizable: false,
              modal: true,
              title: "Are you sure?",
              buttons: [
                {
                  text: "Yes",
                  icons: { primary: "ui-icon-check" },
                  click: function () {
                    $("#breeding_gestation").val(gestationDays);
                    $("#gestation_row .help_text").removeClass("hidden");
                    $(this).dialog("close");
                    in_handler = false;
                    return true;
                  },
                },
                {
                  text: "No",
                  icons: { primary: "ui-icon-cancel" },
                  click: function () {
                    $(this).dialog("close");
                    in_handler = false;
                    return false;
                  },
                },
              ],
            });
        }
      }
    }
  };
})();

// Closure prevents multiple dialogs appearing at once
var updateDueDate = (function () {
  console.debug('updateDueDate()')

  var in_handler = false;

  return function () {
    var serviceDate = maxServiceDate();
    if (serviceDate != null) {
      // Calculate proposed new due on date.
      var newDate = new Date(
        serviceDate + 4 * 60 * 60 * 1000 + 150 * 24 * 60 * 60 * 1000
      );

      // Determine existing due on date.
      var oldDate = null;
      if ($("#breeding_due_on").val() != "") {
        oldDate = ezkParseDate($("#breeding_due_on").val());
      }

      // Prompt to update due on date if appropriate.
      if (oldDate == null) {
        $("#breeding_due_on").val(newDate.toMMDDYYYYString());

      } else if (
        newDate.getYear() == oldDate.getYear() &&
        newDate.getMonth() == oldDate.getMonth() &&
        newDate.getDate() == oldDate.getDate()
      ) {
        // No change, don't do anything.
      } else {
        if (in_handler == false) {
          in_handler = true;
          $("<div />")
            .text("Update due date based on this date?")
            .dialog({
              resizable: false,
              modal: true,
              title: "Are you sure?",
              buttons: [
                {
                  text: "Yes",
                  icons: { primary: "ui-icon-check" },
                  click: function () {
                    $("#breeding_due_on").val(newDate.toMMDDYYYYString());

                    $(this).dialog("close");
                    in_handler = false;
                    return true;
                  },
                },
                {
                  text: "No",
                  icons: { primary: "ui-icon-cancel" },
                  click: function () {
                    $(this).dialog("close");
                    in_handler = false;
                    return false;
                  },
                },
              ],
            });
        }
      }
    }
  };
})();

$(document).on("change", ".breeding_service_date", function () {
  console.debug(`$(document).on("change", ".breeding_service_date"`)

  let $this = $(this);

  if ($this.val() != "") {
    $this.closest(".vtable")
         .find(".vrowgroup.service_date_attributes")
         .removeClass("hidden");
  }

  updateDueDate();
  updateGestation();
});

function currentResultType() {
  return $('input:radio[name="breeding[result_type]"]:checked').val();
}

var lastResultType = null;

function rememberResultType() {
  console.debug('rememberResultType()')

  lastResultType = currentResultType();
}

function hideOutcomeFields() {
  console.debug("hideOutcomeFields()")
  $(
    "#outcome_date_row, #outcome_date_row abbr, tbody.kidding, #kid_source_row, #gestation_row, #outcome_date_required, #breeding_result_row, #kidding_day_notes_row, #kids, #create_lactation_row"
  ).addClass('hidden');
}

$(document).on("change", 'input:radio[name="breeding[result_type]"]', function () {
  console.debug(`$(document).on("change", 'input:radio[name="breeding[result_type]"]'`)

  let $thisRadio    = $(this);
  let $radio        = $('input[name="breeding[result_type]"]');
  let newResultType = currentResultType();

  if (
    $("#kids .kid").length &&
    (lastResultType == "kidding" || lastResultType == "terminated") &&
    (newResultType == "pending" ||
      newResultType == "pregnancy" ||
      newResultType == "unsuccessful")
  ) {
    $("<div />")
      .text(
        "This update will disassociate all kids from this kidding. Are you sure you want to make this update?"
      )
      .dialog({
        resizable: false,
        modal: true,
        title: "Are you sure?",
        buttons: [
          {
            text: "Yes",
            icons: { primary: "ui-icon-check" },
            click: function () {
              $(this).dialog("close");
              updateGestation();
              return true;
            },
          },
          {
            text: "Cancel",
            icons: { primary: "ui-icon-cancel" },
            click: function () {
              $radio.removeAttr("checked");
              $(
                'input[name="breeding[result_type]"][value="' +
                  lastResultType +
                  '"]'
              )
                .attr("checked", "checked")
                .click()
                .trigger("change");
              $(this).dialog("close");
              return false;
            },
          },
        ],
      });
  } else {
    rememberResultType();
  }

  if (this.checked) {
    hideOutcomeFields();

    switch ($thisRadio.attr("id")) {
      case "breeding_result_type_pending":
        $("#kidding_day_notes_row").removeClass("hidden");
        break;
      case "breeding_result_type_pregnancy":
        $("#kidding_day_notes_row, #breeding_result_row").removeClass("hidden");
        break;
      case "breeding_result_type_unsuccessful":
      case "breeding_result_type_unknown":
        $("#breeding_result_row").removeClass("hidden");
        break;
      case "breeding_result_type_kidding":
        $(
          "#outcome_date_row, #outcome_date_row abbr, #gestation_row, #outcome_date_required, #breeding_result_row, #kids, #create_lactation_row"
        ).removeClass("hidden");
        break;
      case "breeding_result_type_terminated":
        $(
          "#outcome_date_row, #outcome_date_row abbr, #gestation_row, #outcome_date_required, #breeding_result_row, #kids, #create_lactation_row"
        ).removeClass("hidden");
        break;
      case "breeding_result_type_false":
        $(
          "#outcome_date_row, #outcome_date_row abbr, #breeding_result_row, #create_lactation_row"
        ).removeClass("hidden");
        break;
      }
    }
  }
);

$(document).on("focus", "#breeding_result_on", function () {
  console.debug(`$(document).on("focus", "#breeding_result_on"`)

  var $element = $(this);
  $element.data("previous", $element.val());
});

$(document).on("change", "#breeding_result_on", function () {
  console.debug(`$(document).on("change", "#breeding_result_on"`)

  updateGestation();
});

// Kids

$(document).on("change", "select.kid_birth_status", function () {
  console.debug('on("change", "select.kid_birth_status")')

  var select = $(this);
  var status = select.val();
  var vtable = select.closest(".vtable");

  if (status == "") {
    vtable.find(".kid_with_status").addClass('hidden');
  } else {
    vtable.find(".kid_with_status").removeClass('hidden');
  }

  switch (status) {
    case "live":
      vtable.find(".birth_status_live").removeClass('hidden');
      vtable.find(".birth_status_dead").addClass('hidden');
      break;
    case "stillborn":
    case "dead":
      vtable.find(".birth_status_dead").removeClass('hidden');
      vtable.find(".birth_status_live").addClass('hidden');
      break;
    default:
      vtable
        .find(
          "#outcome_date_row, tbody.kidding, #kid_source_row, #gestation_row, #outcome_date_required, #breeding_result_row, #kids"
        )
        .removeClass("hidden");
      break;
  }
});

$(document).on("focus", ".input.breeding_kids_birth_order_position select", function () {
    var select = $(this);
    select.data("previous", select.val());
  }
);

$(document).on("change", ".input.breeding_kids_birth_order_position select", function () {
  console.debug(`$(document).on("change", ".input.breeding_kids_birth_order_position select"`)
  var select = $(this);

  select.blur();

  if (select.val() == "") {
    return;
  }

  $(".input.breeding_kids_birth_order_position select").each(function () {
    var thisSelect = $(this);

    if (
      thisSelect.attr("id") !== select.attr("id") &&
      thisSelect.val() === select.val()
    ) {
      $("<div />")
        .text(
          "There is already an animal in this kidding with this birth order position.  Are you sure?"
        )
        .dialog({
          resizable: false,
          modal: true,
          title: "Are you sure?",
          buttons: [
            {
              text: "Yes",
              icons: { primary: "ui-icon-check" },
              click: function () {
                $(this).dialog("close");
                return true;
              },
            },
            {
              text: "Cancel",
              icons: { primary: "ui-icon-cancel" },
              click: function () {
                select.val(select.data("previous"));
                $(this).dialog("close");
                return false;
              },
            },
          ],
        });
      }
    });
  }
);

function clearBredToFields() {
  console.debug('clearBredToFields()')

  $('#breeding_stud_fee').val('');
}

window.clearBredToFields = clearBredToFields;

function showBredToFields() {
  console.debug('showBredToFields()')

  $('.bred_to_fields').removeClass("hidden");
}

window.showBredToFields = showBredToFields;

function hideBredToFields() {
  console.debug('hideBredToFields()')

  $('.bred_to_fields').addClass('hidden');
  clearBredToFields();
}

window.hideBredToFields = hideBredToFields;

function clearNicknameTimeout() {
  console.debug('clearNicknameTimeout()')

  if (nicknameTimeout != null) {
    clearTimeout(nicknameTimeout);
    nicknameTimeout = null;
  }
}

window.clearNicknameTimeout = clearNicknameTimeout;

// Check the value of the nickname field and warn if there is a duplicate
function checkDuplicateKidName(el) {
  var herd_animal_id = $(el)
    .closest("div.kid")
    .find("input.kid_herd_animal_id")
    .val();

  if (
    (herd_animal_id === undefined ||
      herd_animal_id === "" ||
      parseInt(herd_animal_id) < 13) &&
    lastNickname.length > 1

  ) {
    $.getJSON(
      "/check_kid_name.json",
      { nickname: lastNickname },
      function (data) {
        if (data.success === false) {
          alert(
            `The Barn/Nickname ${lastNickname} is already used in your herd. Please enter another Barn/Nickname for this kid.`
          );
          curNicknameField.focus();
        }
      }
    );
  }

  curNicknameField
    .closest("form")
    .find('button[type="Submit"]')
    .button("enable");
}

// Assumes an input with a 'list' attribute.
// Takes the current value of the string in the list and loads herd animal names into the datalist object
// This function is debounced for 500 milliseconds
export function searchAnimalNamesForList() {
  console.debug('searchAnimalNamesForList()')

  var $this         = $(this);
  var name_str      = $(this).val();
  var list_el       = $("datalist#" + $(this).attr("list"));
  var container     = $(this).closest("div.input.birth_status_live");
  var breeding_date = $("input#breeding_result_on").first().val();

  // clear any errors
  let $kid = $this.closest('.kid')
  $('.error_explanation', $kid).remove()
  $this.closest('.field_with_errors').removeClass('field_with_errors')

  if (name_str.length >= 3) {
    $.getJSON(
      "/herd_name_options.json",
      {
        name: name_str,
        born_before: breeding_date
      },
      function (data) {
        console.debug(data)
        /* EXAMPLE FOR FORMAT data WILL HAVE
          {
            "success": true,
            "names": [
              "Wiki"
            ],
            "html": "\u003coption value=\"Wiki\" data-id=\"2881\"\u003e\u003c/option\u003e\n"
          }
        */
        if (data.success === true) {
          list_el.html(data.html);
          $this.focus();

          var matched_id = checkAnimalNameMatch(name_str, list_el);

          if (matched_id === null) {
            container.find(".herd_hint").addClass('hidden');

            // MUST HAPPEN BEFORE WE CLEAR THE herd_animal_id field!
            unhideLinkedKidInformation(container);
            container.find("input.kid_herd_animal_id").val("");

          } else {
            console.debug(`matched_id: ${matched_id}`)
            container.find("div.herd_hint").removeClass('hidden');
            container.find("input.kid_herd_animal_id").val("" + matched_id);
            hideLinkedKidInformation(container);
          }
        }
      }
    );
  } else {
    list_el.html("");
    $this.focus();
  }
}

function checkAnimalNameMatch(name, datalist_el) {
  var matched = null;

  $(datalist_el)
    .children("option")
    .each(function () {
      if (name.toUpperCase() === $(this).val().toUpperCase()) {
        matched = $(this).attr("data-id");
      }
    });

  return matched;
}

// On edit, hides the Herd Animal input fields
export function hideLinkedKidInformation(container) {
  console.debug("hideLinkedKidInformation()")

  $("input.kid_herd_animal_id", container).each(function () {
    var id_prefix      = ("" + $(this).attr("id")).match(/^(.+_\d+_)/);
    var herd_animal_id = parseInt($(this).val());

    if (id_prefix !== null &&
        id_prefix.length > 1 &&
        herd_animal_id !== undefined &&
        herd_animal_id !== ""
    ) {
      hideBreedingKidFields(id_prefix)
    }
  });
}

window.hideLinkedKidInformation = hideLinkedKidInformation;

export function unhideLinkedKidInformation(container) {
  console.debug("unhideLinkedKidInformation()")

  $("input.kid_herd_animal_id", container).each(function () {
    var id_prefix      = ("" + $(this).attr("id")).match(/^(.+_\d+_)/);
    var herd_animal_id = parseInt($(this).val());

    if (id_prefix !== null &&
        id_prefix.length > 1 &&
        herd_animal_id !== undefined &&
        herd_animal_id !== ""
    ) {
      unhideBreedingKidFields(id_prefix)
    }
  });
}

window.unhideLinkedKidInformation = unhideLinkedKidInformation;

// Called by an event handler for the kid nickname input, shows a popup if a name is duplicated
export function checkNicknameInputForDuplicate(event) {
  var _this = $(this);
  curNicknameField = $(event.target);

  if (lastNickname !== $(this).val()) {
    lastNickname = $(this).val();
    clearNicknameTimeout();

    curNicknameField
      .closest("form")
      .find('button[type="Submit"]')
      .button('disable');

    nicknameTimeout = setTimeout(function () {
      checkDuplicateKidName(_this);
    }, 1000);
  }
}

export function hideBreedingKidFields(id_prefix) {
  console.debug("hideBreedingKidFields()")

  $("input#" + id_prefix[1] + "name_temporary").removeAttr("checked").closest("div").addClass("hidden");
  $("input#" + id_prefix[1] + "nickname").parents("div.input").last().addClass("hidden");
  $("input#" + id_prefix[1] + "weight_ounces").parents("div.input").last().addClass("hidden");
  $("input#" + id_prefix[1] + "weight_pounds").parents("div.input").last().addClass("hidden");
  $("input#" + id_prefix[1] + "weight_pounds_decimal").parents("div.input").last().addClass("hidden");
  $("select#" + id_prefix[1] + "birth_order_position").parents("div.input").last().addClass("hidden");
  $("select#" + id_prefix[1] + "conception_type").parents("div.input").last().addClass("hidden");
  $("select#" + id_prefix[1] + "ear_type").parents("div.input").last().addClass("hidden");
  $("select#" + id_prefix[1] + "eye_color_id").parents("div.input").last().addClass("hidden");
  $("select#" + id_prefix[1] + "horn_type").parents("div.input").last().addClass("hidden");
  $("select#" + id_prefix[1] + "jaw_defect_id").parents("div.input").last().addClass("hidden");
  $("textarea#" + id_prefix[1] + "colors_and_markings").parents("div.input").last().addClass("hidden");
  $("textarea#" + id_prefix[1] + "notes").parents("div.input").last().addClass("hidden");
}

export function unhideBreedingKidFields(id_prefix) {
  console.debug("unhideBreedingKidFields()")

  $("input#" + id_prefix[1] + "name_temporary").closest("div").removeClass("hidden");
  $("input#" + id_prefix[1] + "nickname").closest("div.birth_status_live").removeClass("hidden")
  $("input#" + id_prefix[1] + "weight_ounces").parents("div.input").last().removeClass("hidden");
  $("input#" + id_prefix[1] + "weight_pounds").parents("div.input").last().removeClass("hidden");
  $("input#" + id_prefix[1] + "weight_pounds_decimal").parents("div.input").last().removeClass("hidden");
  $("select#" + id_prefix[1] + "birth_order_position").parents("div.input").last().removeClass("hidden");
  $("select#" + id_prefix[1] + "conception_type").parents("div.input").last().removeClass("hidden");
  $("select#" + id_prefix[1] + "ear_type").parents("div.input").last().removeClass("hidden");
  $("select#" + id_prefix[1] + "eye_color_id").parents("div.input").last().removeClass("hidden");
  $("select#" + id_prefix[1] + "horn_type").parents("div.input").last().removeClass("hidden");
  $("select#" + id_prefix[1] + "jaw_defect_id").parents("div.input").last().removeClass("hidden");
  $("textarea#" + id_prefix[1] + "colors_and_markings").parents("div.input").last().removeClass("hidden");
  $("textarea#" + id_prefix[1] + "notes").parents("div.input").last().removeClass("hidden");
}

// Hides herd hint and gender select
// then hides breeding kid fields
export function hideHerdAnimalFieldsOnEdit(container) {
  console.debug("hideHerdAnimalFieldsOnEdit()")

  $("input.kid_herd_animal_id", container).each(function () {
    var id_prefix = ("" + $(this).attr("id")).match(/^(.+_\d+_)/);

    // If this has an id then we know where editing a persisted record
    var hidden_kid_id_input = $(this).closest('.nested-fields').find('.kid_id')
    var kid_id = parseInt(hidden_kid_id_input.val());

    if (id_prefix !== null &&
        id_prefix.length > 1 &&
        kid_id !== undefined &&
        kid_id !== "" &&
        kid_id !== "0"
    ) {

      let $herdHint = $("input#" + id_prefix[1] + "name").closest("div.birth_status_live").find("div.herd_hint")
      $herdHint.removeClass("hidden");

      let $genderWrap = $("select#" + id_prefix[1] + "gender").closest('.controls').closest(".input")
      $genderWrap.addClass("hidden");

      hideBreedingKidFields(id_prefix);
    }
  });
}
