/* Distribution */

import { formFieldOK } from "./functions";
import { formFieldNG } from "./functions";

import 'jquery-ui/ui/widgets/button';
import 'jquery-ui/ui/widgets/datepicker';

import Rails from "@rails/ujs";


function displayKnownDate() {
  console.debug("displayKnownDate()")

  $(".input.unknown_or_estimated_date input.unknown_date").trigger("change");
}

$(document).on("change", ".input.distribution select.standard_distribution", function () {
  console.debug('$(document).on("change", ".input.distribution select.standard_distribution",')

  var $non_standard_distribution = $(this)
    .closest(".input.distribution")
    .find("input.non_standard_distribution");

  if (this.options[this.selectedIndex].value == "Other") {
    $non_standard_distribution.removeClass("hidden");
  } else {
    $non_standard_distribution.addClass("hidden");
  }
});

// May return null if a badly formatted date is passed
export function ezkParseDate(s) {
  console.debug("--> ezkParseDate(s)")

  try {
    return $.datepicker.parseDate("mm/dd/yy", s, {});
  } catch (err) {
    return null;
  }
}

export function ezkParseTime(s) {
  console.debug("-> ezkParseTime(s)")

  if (s == "" || s == null) {
    return 0;
  }

  var date = ezkParseDate(s);

  if (date) {
    var time = date.getTime();

    if (isNaN(time)) {
      return 0;
    } else {
      return time;
    }
  } else {
    return 0;
  }
}

function updateDateFieldToNextDay(field) {
  console.debug("updateDateFieldToNextDay(field)")

  var date;

  if ($(field).val() != "") {
    date = ezkParseDate($(field).val());
    if (date !== null) {
      date.setTime(date.getTime() + 1000 * 60 * 60 * 24);
      $(field).val(date.toMMDDYYYYString());
    }
  }
}

window.updateDateFieldToNextDay = updateDateFieldToNextDay;

/* Herd animal: registration ids */

export function setDefaultPrimaryRegistration(table) {
  console.debug("setDefaultPrimaryRegistration(table)")

  var $selected = table.find(".primary_registration[value=true]");

  if ($selected.length == 0) {
    table.find("tr").each(function () {
      var $tr = $(this);
      if (
        $tr.find(".destroy").length > 0 &&
        $tr.find(".destroy")[0].value != 1
      ) {
        setPrimaryRegistration($tr.find("input.primary"));
      }
    });
  }
}

export function setPrimaryRegistration(radio) {
  console.debug("setPrimaryRegistration(radio)")

  var $radio = $(radio);

  // Set all radios false
  $radio.closest("table")
        .find("input.primary_registration")
        .each(function () {
          this.value = "false";
        });

  // Set desired radio true
  $radio.closest("tr").find("input.primary_registration").val("true");
  $radio[0].checked = true;
}

/* Herd animal: status change choices */

function hideDuplicateDob() {
  console.debug("hideDuplicateDob()")

  // .new_herd_animal / .edit_herd_animal are not defined in Rails views, they auto created
  // from SimpleForm object inspection... these should all be replaced with a [behavior] hook
  // or Stimulus controller.  CI 4/25/24
  $("[data-behavior='hideDuplicateDob'], form.new_herd_animal, form.edit_herd_animal").each(function () {
    let $form    = $(this);
    let $born_on = $form.find(".input.herd_animal_born_on");

    if (
      $form.find(
        ".input.herd_animal_herd_animal_status_changes_status_change_on.date_of_birth"
      ).length != 0
    ) {
      $born_on.parents('.input').addClass("hidden");
    } else {
      $born_on.parents('.input').removeClass("hidden");
    }
  });
}

window.hideDuplicateDob = hideDuplicateDob;

/* Date fields */

// Checks for a format similar to '3/4/2014', returns corrected date string or
// false if illegal format.
export function ekValidateDateFormat(dateStr) {
  console.debug(`--> ekValidateDateFormat("${dateStr}")`)

  dateStr = dateStr.trim();

  if (dateStr.match(/^\d+[./-]\d+[./-]\d{4}$/)) {
    return dateStr;
  } else {
    // check for MMDDYYYY
    var regexp = /^(\d{2})(\d{2})(\d{4})$/;
    var vals = regexp.exec(dateStr);

    if (vals) {
      var mo = parseInt(vals[1]);
      var da = parseInt(vals[2]);
      var yr = parseInt(vals[3]);

      if (yr > 1900 && yr < 3000 && mo > 0 && mo < 12 && da > 0 && da < 32) {
        dateStr = "" + vals[1] + "/" + vals[2] + "/" + vals[3];
        return dateStr;
      }
    }
  }
  return false;
}

// Asserts a valid date format in a date input element. Maybe used in an input blur event.
// Error messages are show at the top of the containing form element
// Returns an updated date string if corrected else the original date string
export function validateSingleDateFieldFormat($el) {
  console.debug(`-> validateSingleDateFieldFormat(${$el[0]})`)
  console.dir($el[0])

  var errMssg = "Please format date fields as MM/DD/YYYY";

  if (typeof $el == "undefined") {
    $el = $(this);
  }

  var dateStr = $el.val();

  // If this field is required and blank, we're going to show an error msg
  if($el.closest('.input.required').length > 0 && dateStr == "") {
    dateStr = false

  } else if (dateStr != "") {
    var newDateStr = ekValidateDateFormat(dateStr, $el.closest("form"));

    if (newDateStr !== false && newDateStr != dateStr) {
      $el.val(newDateStr);
    }

    dateStr = newDateStr; // a date string or false
  }

  // Add or remove red box and form error message
  if (dateStr === false) {
    $('.waiting').removeClass('waiting')
    formFieldNG($el, errMssg);
  } else {
    formFieldOK($el);
  }

  return dateStr;
}

// To be called as part of a form submit event. Checks for all inputs with class date_field and
// confirms correct date format. Returns true if all are OK, else false. Ignores empty date inputs.
export function validateDateFieldsFormat($form) {
  console.debug(`validateDateFieldsFormat(${$form[0]})`)
  console.dir($form[0])

  var success = true;

  if (typeof $form == "undefined") {
    $form = $(this);
  }

  // Only validate date fields that are NOT hidden
  $form
    .find("input.date_field:not(:hidden)")
    .each(function () {
      if (validateSingleDateFieldFormat($(this)) === false) {
        success = false;
        $('.waiting').removeClass('.waiting')
        return false;
      }
    });

  return success;
}

/* Form buttons */
/* TODO: This is beyond bad. Shit like this slows down ALL load time on the site. I had no idea that it was here, but it will need to be remedied. <2014-07-05> */
export function decorateButtons() {
  console.debug("decorateButtons()")


  $("input[type=submit], button, a.button").not('.ui-button').each(function () {
    var $this = $(this);
    console.debug($this)

    var icon = null;
    var text = $.trim($this.val() || $this.text());

    if (text.match(/Cancel/)) {
      icon = "ui-icon-cancel";
    } else if (text.match(/Save|Ok|Yes/)) {
      icon = "ui-icon-check";
    } else if (text.match(/Transfer/)) {
      icon = "ui-icon-transferthick-e-w";
    } else if (text.match(/Continue|Start/)) {
      icon = "ui-icon-arrowthick-1-e";
    } else if (text.match(/Login/)) {
      icon = "ui-icon-locked";
    } else if (text.match(/Update/)) {
      icon = "ui-icon-arrowrefresh-1-e";
    } else if (text.match(/Add/)) {
      icon = "ui-icon-plus";
    } else if (text.match(/Create Group/)) {
      icon = "ui-icon-plus";
    }
    if (icon) {
      $this.button({ icons: { primary: icon } });
    } else {
      $this.button();
    }
  });
}

window.decorateButtons = decorateButtons;

//-------------------------------------
// ATTACH EVENTS

// On Page Load
$(function () {
  console.debug('$(function ()  PAGE LOAD')

  decorateButtons();
  displayKnownDate();
  hideDuplicateDob();

  $(".input.distribution select.standard_distribution").trigger("change");
  $(".input.id_tags select.id_tag_placement").trigger("change");

  if (location.hash) {
    console.debug('$(function) location.hash')

    let $locationHashElement = $(`.registration_options .${location.hash.substr(1)}` );

    if($locationHashElement.length > 0) {
      // can't use jQuery trigger(event) as Stimulus doesn't listen for jquery events
      $locationHashElement[0].dispatchEvent(new Event('click'));
    }
  }
});

/* Unknown / estimated dates */

$(document).on("change", ".input.unknown_or_estimated_date input.unknown_date",
  function () {
    console.debug('$(document).on("change", ".input.unknown_or_estimated_date input.unknown_date")')

    var $known_date = $(this)
                        .closest(".input.unknown_or_estimated_date")
                        .find(".known_date");

    if ($(this).is(":checked")) {
      $known_date.addClass("hidden");
    } else {
      $known_date.removeClass("hidden");
    }
  }
);

/* Herd animal: gender */

//
// .registration_ids is the form elements wrapping div for "Registration/Id #(s):"
// There is a 'Add Registration' and 'Cancel' button within
//
$(document).on("click", ".registration_ids a.toggle-new-registration-form",
  function () {
    console.debug('$(document).on("click", ".registration_ids a.toggle-new-registration-form"')

    let $registrationIds = $(this).closest(".registration_ids")

    $registrationIds.find(".add_registration_fields")
                    .toggleClass('hidden');

    // remove button spinner
    $registrationIds.find('button.waiting').removeClass('waiting')

    var $errorBox = $registrationIds.find(".add_registration_error")
    $errorBox.addClass('hidden')
    $errorBox.html('')

    return false;
  }
);

// Add Herd Animal Registration - 'Registration Pending' checkbox
$(document).on("click", ".registration_ids .add_registration_fields .pending input[type=checkbox]",
  function () {
    console.debug('$(document).on("click", ".registration_ids .add_registration_fields .pending input[type=checkbox]"')

    $(this)
      .closest(".add_registration_fields")
      .find(".registration_id")
      .toggleClass('hidden');
    return true;
  }
);

// Add Herd Animal Registration Cancel Button
$(document).on("click", ".registration_ids .add_registration_fields button.cancel",
  function () {
    console.debug('$(document).on("click", ".registration_ids .add_registration_fields button.cancel"')

    $(this).closest(".add_registration_fields").addClass('hidden');
    return false;
  }
);

// Allow buttons with rel=reload to refresh page
$(document).on("click", "button[rel=reload]", function (event) {
  console.debug('$(document).on("click", "button[rel=reload]", function ()')

  event.preventDefault();
  window.location.reload();

  return false;
});

function elementInDialog(element) {
  let $element = $(element)

  if($element.closest('#modalDialog').length > 0)
    return true

  return false
}

/* Form Ajax start and complete (spinners, verify date) */
//
// On ALL form submit buttons
// If date field validations don't pass stop default form submit
// scroll to top of page to show errors
Rails.delegate(document, Rails.formInputClickSelector, "click", function() {
  console.debug('Rails.delegate(document, Rails.formInputClickSelector, "click"');
  console.debug(`Rails.formSubmitSelector: ${Rails.formSubmitSelector}`);
  console.debug(this);

  let $element = $(this);
  let $dialog  = $('#modalDialog')
  let $form    = $element.closest('form')
  let inDialog = elementInDialog($element);

  // Add spinner to button
  $element.addClass('waiting');

  if (validateDateFieldsFormat($form)) {
    // *_spinner is the animated loading bar!

    if(inDialog) {
      $dialog.find(".dialog_spinner").removeClass("hidden");
    } else {
      $form.find(".form_spinner").removeClass("hidden");
    }

    return true
  } else {
    $form
      .find(Rails.formInputClickSelector)
      .removeAttr("disabled")
      .removeClass("waiting");

    if(inDialog) {
      $dialog.find(".dialog_spinner").addClass("hidden");
    } else {
      $form.find(".form_spinner").addClass("hidden");
    }

    $("html, body").animate({
        scrollTop: $(".error_explanation").offset().top,
      },
      600
    );

    return false;
  }
});

$(document).on('ajax:before', '.dhir_options form', function() {
  window.App.clearSearchResults()
})

$(document).on('ajax:before', '.dhir_options form', function() {
  window.App.clearFlash()
})

$(document).on("ajax:complete", function () {
  // 8/18/23 CI
  // This timeout must be here so that we can guarantee this callback fires
  // after modal window contents are updated.
  // Rails actions like herd_animals/chose_bre_to.js.erb replace whole contents of modal
  // including the data-remote link that may be present which will break Rails ujs ajax:*
  // callbacks since the link that initiated the call is now gone.  So we timeout in that
  // view and we need to timeout here slightly longer!!!  not a problem when a model is
  // just loaded with content, only on ajax replacement from modal
  setTimeout(function () {
    console.debug('$(document).on("ajax:complete", function ()')

    decorateButtons()
    displayKnownDate()

    hideDuplicateDob()

    $(".spinner").addClass('hidden');
    $('.waiting').removeClass('waiting')

    $(Rails.formInputClickSelector).each(function () {
      $(this).removeAttr("disabled").removeClass('waiting');
    });
  }, 500);
});
