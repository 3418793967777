function hideAdvancedFilters() {
  console.debug('hideAdvancedFilters()');

  $("#advanced_filters_on").removeClass("hidden");
  $("#advanced_filters, #advanced_filters_off").addClass("hidden");
  $("#show_advanced_filters").val(false);
}

function showAdvancedFilters() {
  console.debug('showAdvancedFilters()');

  $("#advanced_filters, #advanced_filters_off").removeClass("hidden");
  $("#advanced_filters_on").addClass("hidden");
  $("#show_advanced_filters").val("1");
}

function basicClearFilters() {
  console.debug('basicClearFilters()');

  $("a#clear_filters").click(function (e) {
    console.debug(`$("a#clear_filters").click`);

    $("form#filter-animals-form input[type=text]").val("");
    $("form#filter-animals-form select").val("All");
    $("#filter-animals-form button").removeAttr("disabled");
    $("#outcome").val("all");
    $("form#filter-animals-form select[name=status]").val("all");

    e.preventDefault();
  });
}

window.basicClearFilters = basicClearFilters;

$(function () {
  if ($("#show_advanced_filters").val() == "1") {
    showAdvancedFilters();
  } else {
    hideAdvancedFilters();
  }

  $("#advanced_filters_on").click(showAdvancedFilters);
  $("#advanced_filters_off").click(hideAdvancedFilters);
});
