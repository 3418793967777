import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="preferences"
export default class extends Controller {
  connect() {
    let self = this;

    this.initDropdownArrows()

    $('.ezkdropdown').on('show.bs.dropdown', function () {
      self.open()
    })

    $('.ezkdropdown').on('hide.bs.dropdown', function () {
      self.close()
    })
  }

  disconnect() {
    $('.ezkdropdown').off('show.bs.dropdown')

    $('.ezkdropdown').off('hide.bs.dropdown')
  }

  initDropdownArrows() {
    this.close()
  }

  open() {
    // hide both icons
    $("i.pref-icon").css("display", "none");
    // show closed
    $("i#pref-icon-open").css("display", "inline");
  }

  close() {
    // hide both icons
    $("i.pref-icon").css("display", "none");
    // show open
    $("i#pref-icon-closed").css("display", "inline");
  }
}
