import { Controller } from "@hotwired/stimulus"
import { validateDateFieldsFormat } from "../original_easykeeper/inputs"

// Connects to data-controller="health-event-conflict-check"
export default class extends Controller {
  static values = {
    herdAnimalId: Number
  }

  connect() {

  }

  // Triggered from /health_events/_fields
  // when occurred_on date changes
  checkForEventConflicts(event) {
    console.debug('health-event-conflict-check#checkForEventConflicts"')

    let $form                          = $(event.target.closest('form'))
    let health_event_category          = $('#category').val()
    let health_event_occurred_on_value = $('#health_event_occurred_on').val()

    if(validateDateFieldsFormat($form) && health_event_occurred_on_value) {
      // from unique_test_herd_animal_health_events_path
      //
      let path = `/herd_animals/${this.herdAnimalIdValue}/health_events/unique_test`

      $.ajax({
        url: path,
        dataType: 'script',
        data: {
          health_event_activity_id: $('#health_event_health_event_activity_id').val(),
          category: health_event_category,
          occurred_on: health_event_occurred_on_value
        }
      });
    }
  }

  // objectToQueryString(obj) {
  //   const params = new URLSearchParams();

  //   for (const [key, value] of Object.entries(obj)) {
  //     params.append(key, value);
  //   }

  //   return params.toString();
  // }
}
