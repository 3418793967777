import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tattoos"
export default class extends Controller {
  connect() {
    this.handleVisibility()
  }

  handleVisibility() {
    console.debug('change->tattoos#handleVisibility:prevent')

    let $controls = $(this.element).closest(".controls");
    let selected  = this.element.options[this.element.selectedIndex].value;

    $controls
      .find(".tattoo_fields_ear, .tattoo_fields_tail_rl, .tattoo_fields_tail_c")
      .addClass("hidden");

    if (selected != "") {
      $controls.find(".tattoo_fields_" + selected).removeClass("hidden");
    }
  }
}
