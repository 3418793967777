import 'jquery-ui/ui/widgets/dialog'
import 'jquery-ui/ui/widgets/progressbar'
import { decorateButtons } from './inputs'

// global dialog defaults
window.dialogOptions = {
  position: {
    my: "top",
    at: "top+3%",
    of: window
  },
  modal: true,
  maxHeight: ($(window).height() - 50),
  width: 600
}

export function loadingBar() {
  console.debug('loadingBar()')
  let loadingBarUrl = $('#loading-animation-gif').attr('src')
  let $loadingBar   = $("<img/>", { src: loadingBarUrl })

  return $loadingBar
}

function showDialogSpinner() {
  console.debug('showDialogSpinner()')

  $(".spinner").removeClass("hidden");
}

function closeDialog() {
  console.debug('closeDialog()')

  $("#modalDialog").dialog("close");
  return true;
}

function randomIntFromInterval(min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

// Use destructuring to get named arguments in JS
export function fillDialog({html, showLoadingBar=true}) {
  $("#modalDialogContent").addClass("hidden").html('')

  if(showLoadingBar) {
    $(".spinner", "#modalDialog").removeClass("hidden");
  }

  setTimeout(function(){
    $("#modalDialogContent").html(html);

    decorateButtons()

    $(".spinner", "#modalDialog").addClass("hidden");
    $("#modalDialogContent").removeClass("hidden")
  }, 1000)
}

window.fillDialog = fillDialog;

function contentDialog(content, dialogOptions) {
  console.debug('contentDialog(content, dialogOptions)')

  $.extend(dialogOptions, { position: { my: "top", at: "top+3%", of: window }, modal: true });

  $("#modalDialogContent").html(content);
  $("#modalDialog").dialog(dialogOptions);
}

function submitConfirm(element, message) {
  console.debug('submitConfirm(element, message)')

  $("<div />")
    .text(message)
    .dialog({
      resizable: false,
      modal: true,
      title: "Are you sure?",
      buttons: [
        {
          text: "Yes",
          icons: { primary: "ui-icon-check" },
          click: function () {
            $(this).dialog("close");
            $(element).closest("form").submit();
            return true;
          },
        },
        {
          text: "Cancel",
          icons: { primary: "ui-icon-cancel" },
          click: function () {
            $(this).dialog("close");
            return false;
          },
        },
      ],
    });
}

// Make functions global as per app needing :-(
window.closeDialog       = closeDialog;
window.contentDialog     = contentDialog;
window.submitConfirm     = submitConfirm;

//-------------------------------------
// ATTACH EVENTS

$(function () {
  // Show spinner on rotate photo click
  $("a.image_rotate_action").on("click", function () {
    console.debug('$("a.image_rotate_action").on("click", function ()')

    $("div#loading").removeClass("hidden");
  });

  $(document).on("submit", ".ui-dialog form.dialogSpinner", showDialogSpinner);
  $(document).on("click", ".ui-dialog a.dialogSpinner", showDialogSpinner);
});
