import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="herd-animal-status-change"
export default class extends Controller {
  connect() {
    let $element = $(this.element)
    $element.focus()

    // Why do we need this?  Taken from a JS blob in herd_animal_status_changes/new
    $('#edit_herd_animal_status').show();

    this.change()
  }

  change(event) {
    let selectedValue = this.element.options[this.element.selectedIndex].value;

    $('.HerdAnimalStatusChangeForm').hide();
    $('.' + selectedValue + 'Form').show();
  }
}
