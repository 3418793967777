import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="source-status-change"
export default class extends Controller {
  connect() {

  }

  update_status(event) {
    let $radio   = $(event.currentTarget);
    let $form    = $radio.closest("form");
    let $spinner = $form.find(".status_spinner");
    let $statusChangeFields = $form.find(".status_change_fields")

    // Cleanup form by remove error styling
    let $fieldWithErrors = $radio.closest('.input.field_with_errors')
    $fieldWithErrors.removeClass('field_with_errors')

    let $wrapperDiv = $radio.closest('.controls.error')

    $wrapperDiv.removeClass('error')
    $wrapperDiv.find('div.error').remove()

    // clean end

    $statusChangeFields.html("");

    $form.find("input.button, input.submit, button")
         .button("disable");

    let url = $radio.attr("data-url")

    $.ajax({
      url: url,
      data: {
        form_dom_id: $form.attr('id')
      }
    }).success(function (data) {

      let $statusChangeFields = $form.find(".status_change_fields")
      $statusChangeFields.html(data);

      $form.find("input.button, input.submit, button")
           .button("enable");

      hideDuplicateDob();
    });
  }
}
