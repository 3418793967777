import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

// Connects to data-controller="health-events"
export default class extends Controller {
  connect() {
    // jquery validations adds a novalidate attribute
    // we only want to initialize the validator once
    if(!$('.form-for-bulk-health-events')[0].hasAttribute('novalidate')) {
      console.log("VALIDATOR attached to .form-for-bulk-health-events")

      // validate() defaults set in application.js with $.validator.setDefaults()
      $(".form-for-bulk-health-events").validate({});
    }

    let $batchAnimalForm = $(this.element)
    let $elem            = $batchAnimalForm.siblings('.bulk-animal-selection-checkbox')
    let checked          = $elem.is(':checked')

    console.debug($batchAnimalForm)

    // before validations can be added, validate() must be called on form
    window.setValidations($batchAnimalForm)

    $batchAnimalForm.find(':input').not('button').each((index, input) => {
      if(checked) {
        $(input).prop('disabled', false)
      } else {
        $(input).prop('disabled', true)
      }
    })

    if(checked) {
      $batchAnimalForm.removeClass('hidden');
    } else {
      $batchAnimalForm.addClass('hidden');
    }

    $batchAnimalForm.siblings('.bulkAnimalName')
                    .toggleClass('selected', $elem.is(':checked'));
  }

  removeTemplate(event) {
    console.debug('removeTemplate')

    let $link    = $(event.currentTarget)
    let $subform = $($link.closest(".batchEventForm"));

    window.toggleSubform($subform);
  }

  duplicateInfo(event) {
    console.debug('duplicateInfo(event)')

    let $learnMoreLink = $(event.currentTarget);
    let dialogType     = $learnMoreLink.data("type");
    let message        = event.params.confirm;

    function okCallback() {
      let answer = true;
      let $subform = $($learnMoreLink.closest(".batchEventForm"));

      window.toggleSubform($subform);
      Rails.fire($learnMoreLink[0], "confirm:complete", [ answer ])
    }

    if (dialogType === "BEHEwarningInfo") {
      Rails.showBeheWarningDialog($learnMoreLink, message, okCallback);

    } else if (dialogType === "BEHEerrorInfo") {
      Rails.showBeheErrorDialog($learnMoreLink, message, okCallback);

    } else if (dialogType === "BEHEDateBoundInfo") {
      Rails.showBeheDateBoundDialog($learnMoreLink, message, okCallback);

    } else {
      Rails.showConfirmDialog($learnMoreLink, message, okCallback);
    }
  }
}
