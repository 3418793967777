import { Controller } from "@hotwired/stimulus"
import { decorateButtons } from "../original_easykeeper/inputs";

// Connects to data-controller="dhir-lookup"
export default class extends Controller {
  connect() {
  }

  renderApiWarningMessage(errorMessage) {
    $('#dhir-errors').append(`<div id='warning'>${errorMessage}</div>`);
  }

  renderApiErrorMessage(errorMessage) {
    $('#dhir-errors').append(`<div id='error'>${errorMessage}</div>`);
  }

  removeApiMessages() {
    $('#dhir-errors').html("")
  }

  submit() {
    this.removeApiMessages()

    let $form    = $(this.element).closest('form')
    let url      = $form.attr('action')
    let $button  = $form.find('.submit')
    let $q_input = $form.find("input[name='q']")
    let self     = this;

    if(url) {
      $button.addClass('waiting')

      $.ajax({
         url: url,
         type: "POST",
         dataType: "script",
         data: {
           q: $q_input.val()
         }
       })
       .done(function () {
         $button.removeClass('waiting')
         decorateButtons()
       })
       .fail(function() {
         self.removeApiMessages()
         self.renderApiWarningMessage("This is taking longer than we expected, please bear with us. Do not leave or refresh the page just yet.")

         $button.addClass('waiting')

         $.ajax({
           url: url,
           type: "POST",
           dataType: "script",
           data: {
             q: $q_input.val()
           }
         })
         .done(function () {
           $button.removeClass('waiting')
           decorateButtons()
         })
         .fail(function() {
           $button.removeClass('waiting')
           self.removeApiMessages()
           self.renderApiErrorMessage("Unable to complete request. The remote service is taking too long to respond. Please try again momentarily.")
         })
       });
    }
  }
}
