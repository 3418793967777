import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="health-event-test-results"
export default class extends Controller {
  connect() {
    this.toggleResultReceived()
  }

  toggleResultReceived(event) {
    let $selectField = $(this.element)

    if (
      $selectField.find('option')
                  .filter(":selected")
                  .text() == "Pending"
    ) {
      $(".input.health_event_result_on").addClass("hidden");
    } else {
      $(".input.health_event_result_on").removeClass("hidden");
    }
  }
}
