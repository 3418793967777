import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="selected-party"
export default class extends Controller {
  connect() {
  }

  remove() {
    let $removeLink  = $(this.element)
    let $party       = $removeLink.closest("[data-behavior='party']");
    let $hiddenInput = $party.find("[data-behavior='destroy-field']");

    if ($hiddenInput.length > 0) {
      // Previously saved party.  Need to tell server to delete.
      $hiddenInput.val(true);
      $party.addClass("hidden");
    } else {
      // Only added in-page. Just delete the UI.
      $party.remove();
    }
  }
}
