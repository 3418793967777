import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="party-select"
export default class extends Controller {
  connect() {
    let $select = $(this.element)

    $select.on("ajax:beforeSend", function () {
      $("[data-behavior='party-spinner']").removeClass("hidden");
    })

    $select.on("ajax:complete", function () {
      setTimeout(() => {
        $("[data-behavior='party-spinner']").addClass("hidden")
      }, 500)
    })
  }

  disconnect() {
    let $select = $(this.element)

    $select.off("ajax:beforeSend")
    $select.off("ajax:complete")
  }
}
