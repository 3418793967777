import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pedigrees"
export default class extends Controller {
  connect() {
  }

  editChooser(event) {
    let $element = $(event.currentTarget);
    let $container = $element.closest(".ui-dialog");

    // Update selected tab
    $element.closest(".tab-options").find("a").removeClass("selected");
    $element.addClass("selected");

    $container.find(".pedigree_chooser").removeClass("hidden");
    $("#edit_chooser").removeClass("hidden");

    // Clear search results
    $container.find(".search_results").html("");
  }
}
