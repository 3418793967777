import { Controller } from "@hotwired/stimulus"
import { decorateButtons } from '../original_easykeeper/inputs'

// Connects to data-controller="lookup-animal"
export default class extends Controller {
  static targets = ["checkbox"]

  connect() {

  }

  hideSpinner(wrapper) {
    if(wrapper)
      wrapper.find(".form_spinner").addClass("hidden");
    else
      $(".form_spinner").addClass("hidden");
  }

  showSpinner(wrapper) {
    if(wrapper)
      wrapper.find(".form_spinner").removeClass("hidden");
    else
      $(".form_spinner").removeClass("hidden");
  }

  renderApiErrorMessage(wrapper, errorMessage) {
    wrapper.find(".animal_content")
           .append(`<span class='api-message'>${errorMessage}</span>`);
  }

  removeApiErrorMessage(wrapper) {
    wrapper.find(".api-message").remove();
  }

  // After using "Add Animals to Herd" -> Look up animal by name
  // A list of checkboxes is presented.
  // When one is checked we begin importing
  // the given animals pedigrees which can Heroku timeout.  If we timeout, retry
  // request as prior screen scraping for pedigrees will be cached and we'll pick up
  // where prior request left off.  Hopefully completing
  select() {
    let $checkbox = $(this.checkboxTarget)
    let $li       = $checkbox.closest("li");
    let url       = $checkbox.data("url");
    let self      = this;

    if (url) {
      if (!this.checkboxTarget.checked) {
        $li.find(".animal_content").html("")
      } else {
        this.showSpinner($li)

        $.ajax({ url: url, type: "POST" })
         .done(function () {
           decorateButtons()
           self.hideSpinner($li)
           self.removeApiErrorMessage($li)
         })
         .fail(function() {
           self.removeApiErrorMessage($li)
           self.renderApiErrorMessage($li, "This is taking longer than we expected, please bear with us. Do not leave or refresh the page just yet.")

           $.ajax({ url: url, type: "POST" })
            .done(function () {
              decorateButtons()
              self.hideSpinner($li)
              self.removeApiErrorMessage($li)
            })
            .fail(function() {
              self.hideSpinner($li)
              self.removeApiErrorMessage($li)
              self.renderApiErrorMessage($li, "Unable to complete request. The remote service is taking too long to respond. Please try again momentarily, by re-toggling the checkbox.")
            })
         });
      }
    } else {
      $li.find(".search-result-already-in-herd").toggleClass('hidden');
    }
  }
}
