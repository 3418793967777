import { monthDiff } from "../functions";
import { ekValidateDateFormat, ezkParseDate } from "../inputs";

function update_age_at_freshening() {
  let herdAnimalDob        = $("#lactation_freshened_on").data('dateOfBirth')

  if (herdAnimalDob == null) {
    return false;
  }

  var freshened_on = $("#lactation_freshened_on").val();

  if (freshened_on == "") {
    return false;
  }

  var dateOfFreshening = new Date(freshened_on);
  var totalMonths      = monthDiff(herdAnimalDob, dateOfFreshening);
  var months           = totalMonths % 12;
  var years            = (totalMonths - months) / 12;

  if (months < 10) {
    months = "0" + months;
  }

  $("#age_at_freshening_years").html(years);
  $("#age_at_freshening_months").html(months);
}

function freshened_date_change_handler() {
  if (ekValidateDateFormat($(this).val()) !== false) {
    let isNewLactationRecord = $("#lactation_freshened_on").data('isNewLactationRecord')

    update_age_at_freshening();

    if (isNewLactationRecord) {
      update_dry_on();
    }
  }
}

window.update_age_at_freshening = update_age_at_freshening;
window.freshened_date_change_handler = freshened_date_change_handler;

// Closure prevents multiple dialogs appearing at once
var update_dry_on = (function () {
  var in_dry_handler = false;

  return function () {
    if ($("#lactation_freshened_on").val() == "") {
      return false;
    }

    var value = $("#lactation_freshened_on").val();

    if (value != "") {
      // Confirm valid date format
      value = ekValidateDateFormat(value);

      if (value === false) {
        return false;
      }

      // Calculate proposed new due on date.
      var relevantDate = ezkParseDate(value);
      var newDate = new Date(
        relevantDate.getTime() + 4 * 60 * 60 * 1000 + 305 * 24 * 60 * 60 * 1000
      );

      // Determine existing due on date.
      var oldDate = null;

      if ($("#lactation_dry_on").val() != "") {
        oldDate = ezkParseDate($("#lactation_dry_on").val());
      }

      // Prompt to update due on date if appropriate.
      if (oldDate == null) {
        $("#lactation_dry_on").val(newDate.toMMDDYYYYString());
      } else if (
        newDate.getYear() == oldDate.getYear() &&
        newDate.getMonth() == oldDate.getMonth() &&
        newDate.getDate() == oldDate.getDate()
      ) {
        // No change, don't do anything.
      } else {
        if (in_dry_handler == false) {
          in_dry_handler = true;

          $("<div />")
            .text("Update date to be dried based on new lactation start date?")
            .dialog({
              resizable: false,
              modal: true,
              title: "Are you sure?",
              buttons: [
                {
                  text: "Yes",
                  icons: { primary: "ui-icon-check" },
                  click: function () {
                    $("#lactation_dry_on").val(newDate.toMMDDYYYYString());
                    $(".help_text").removeClass("hidden");

                    $(this).dialog("close");
                    in_dry_handler = false;

                    return true;
                  },
                },
                {
                  text: "Cancel",
                  icons: { primary: "ui-icon-cancel" },
                  click: function () {
                    $(this).dialog("close");
                    in_dry_handler = false;
                    return false;
                  },
                },
              ],
            });
        }
      }
    }
  };
})();
