import { Controller } from "@hotwired/stimulus"

import PhotoSwipe from 'photoswipe';
import PhotoSwipeLightbox from 'photoswipe/lightbox';

// Connects to data-controller="photoswipe"
export default class extends Controller {
  connect() {
    const lightbox = new PhotoSwipeLightbox({
      gallery: '.gallery',
      children: 'a.herd_animal_photo',
      pswpModule: PhotoSwipe
    });

    lightbox.init();
  }
}
