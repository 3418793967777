// console.debug('/javascript/controllers/application.js')

import { Application } from "@hotwired/stimulus";

const application = Application.start();

// Configure Stimulus development experience
const debug = process.env.NODE_ENV === "development" ? true : false;

export { debug }

// https://github.com/hotwired/stimulus/pull/354
// In debug mode application lifecycles, controller lifecycles and actions events are sent to the Stimulus Logger with a default output to the console
application.debug = debug;

window.Stimulus = application;

export { application };
