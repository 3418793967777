// check to see if CC number checks out formatting-wise
function ccLuhn(cc) {
	// digits 0-9 doubled with nines cast out
	var doubled = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];

	// remove non-digit characters
	cc = cc.replace(/[^\d]/g, "");
	var digits = cc.split("");

	// alternate between summing the digits
	// or the result of doubling the digits and
	// casting out nines (see Luhn description)
	var alt = false;
	var total = 0;
	while (digits.length) {
		var d = Number(digits.pop());
		total += alt ? doubled[d] : d;
		alt = !alt;
	}

	//return total % 10 == 0;
	// display an error if necessary
	if (total % 10 != 0) {
		$("#ccError").html(
			"<strong><font color='red'>Invalid credit card number</font></strong>"
		);
		$("#subscription_full_number").focus();
		$("#__credit_card_number_div").addClass("field_with_errors");
		$("#subscription_submit").button("disable");
		$("#subscription_submit").css("cursor", "not-allowed");
		$("#subscription_card_type").val("error");
	} else {
		var type = ccType(cc);

		$("#subscription_card_type").val(type);

		if (type == "unknown") {
			type =
				"<font color='red'>Credit card type not allowed. Accepted credit cards are Visa, Mastercard, Discover and American Express.</font>";
			$("#subscription_submit").attr("disabled", "disabled");
			$("#subscription_submit").css("cursor", "not-allowed");
		} else {
			$("#__credit_card_number_div").removeClass("field_with_errors");
			$("#subscription_submit").removeAttr("disabled");
			$("#subscription_submit").css("cursor", "pointer");
			$("#subscription_submit").css("cursor", "hand");
		}
		$("#ccError").html("<strong>" + type + "</strong>");
	}
}

window.ccLuhn = ccLuhn;

// check credit card type
function ccType(cc) {
	// regular expressions to match common card types
	// delete or comment out cards not accepted
	// see: www.merriampark.com/anatomycc.htm
	var cardpatterns = {
		visa: /^(4\d{12})|(4\d{15})$/,
		mastercard: /^5[1-5]\d{14}$/,
		discover: /^6011\d{12}$/,
		amex: /^3[47]\d{13}$/,
		// 'diners'     : /^(30[0-5]\d{11})|(3[68]\d{12})$/
	};

	// return type of credit card
	// or 'unknown' if no match
	// remove non-digits
	cc = cc.replace(/[^\d]/g, "");

	for (var type in cardpatterns) {
		if (cardpatterns[type].test(cc)) return type;
	}
	return "unknown";
}
