import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="manual-add-animal"
export default class extends Controller {
  connect() {
  }

  showForm() {
    let $context       = $(this.element).closest(".search_context");
    let $registeredTab = $context.find(".registration_options .registered");

    $context.find("#matches").addClass("hidden");

    let $otherOptions = $context.find(".unregistered_options:visible, .pending_options:visible, .registered_manual_options:visible")

    if ( $otherOptions.length > 0 ) {
      $context.find(".pending_options").addClass("hidden");
      $context.find(".manual_add_form").removeClass("hidden");
    } else {
      // Trigger click action in another Stimulus controller
      $registeredTab[0].dispatchEvent(new Event('click'))
      $context.find(".registered_options .registered_manual")[0].dispatchEvent(new Event('click'))
    }
  }
}
