import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="add-animals"
export default class extends Controller {
  connect() {

  }

  toggleRegistrationTabs(event) {
    let $this, $searchContext;

    $this = $(event.currentTarget);
    $searchContext = $this.closest(".search_context");

    // remove .selected class from all tabs
    $this.closest(".registration_options").find("a").removeClass("selected");

    // get active tab class name before we add .selected, we don't want that included
    let activeTabClassName = $this.attr("class");

    // add .selected class to tab we clicked
    $this.addClass("selected");

    // hide all tabs contents
    $searchContext.find(".search_options").addClass("hidden");
    // clear out search_results
    $searchContext.find(".search_results").html("");
    // remove any present errors
    $searchContext.find("#error").remove();
    // find active tabs contents and show, then focus first visible input
    $searchContext
      .find(`.${activeTabClassName}_options`).removeClass("hidden")
      .find(":input:visible:enabled:first").focus();
  }

  // these aren't the "tabs", they're the link options under the 'Add registerd aniaml' Tab
  toggleRegisteredOptions(event) {
    let $this, $registeredOptions;

    $this              = $(event.currentTarget);
    $registeredOptions = $this.closest(".registered_options");

    // get active tab class name before we add .selected, we don't want that included
    let activeLinkClassName     = $this.attr("class");
    let activeFindContentsClass = `.${activeLinkClassName}_options`;
    let $activeFindContents     = $(activeFindContentsClass);
    let $searchOptions          = $registeredOptions.find(`.search_options`)

    // remove any present errors
    $searchOptions.find('#error').remove()

    // hide all links contents
    $searchOptions.addClass("hidden");
    $activeFindContents.removeClass('hidden');
  }
}
