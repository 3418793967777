// Entry point for the build script in your package.json

// console.debug('/javascript/application.js')

// Entry point for the build script in your package.json
import "./controllers";
import "./channels";

import Rails from "@rails/ujs";

import "./original_easykeeper/confirm";

Rails.start()
window.Rails = Rails;

// import Turbolinks from "turbolinks"
// Turbolinks.start()

// import * as ActiveStorage from "@rails/activestorage"
// ActiveStorage.start()

import Cookies from "js-cookie"
window.Cookies = Cookies

import "jquery-ui";
import "jquery-validation";
import "@nathanvda/cocoon"
import 'sortable-tablesort/sortable'
import "./original_easykeeper";

$(window.document).ready(() => {
  // Set the browser's timezone in a cookie for retrieval on the server
  if (location.protocol === 'https:') {
    Cookies.set("browser.timezone", Intl.DateTimeFormat().resolvedOptions().timeZone, { expires: 365, secure: true })
  } else {
    // allow for readable cookie value in dev/test
    Cookies.set("browser.timezone", Intl.DateTimeFormat().resolvedOptions().timeZone, { expires: 365 })
  }

})

window.App ||= {
  maxHealthTemplateForms: 3,
  openOverlay: () => {
    if($('.ui-widget-overlay').length == 0)
      $('body').append($('<div/>', { class: 'ui-widget-overlay' }))
  },
  closeOverlay: () => {
    $('.ui-widget-overlay').remove()
  },
  closeOverlayAndStopWaiting: () => {
    window.App.closeOverlay()
    $('button.waiting').removeClass('waiting')
  },
  clearFlash: () => { $("#messages").html("") },
  flash: (message, flashType = 'alert') => {
    $("#messages").html($(`<div/>`, { id: flashType, text: message}))
  },
  clearBulkMilkRecords: () => { $('#bulk_milk_records').html('') },
  clearSearchResults:   () => { $('#search_results').html('') }
}

/******************************************/
/************** Validations ***************/
/******************************************/
/* This overwrite ensures that tags with different ids are validated separately */
$.validator.prototype.checkForm = function () {
  this.prepareForm();

  for (
    var i = 0, elements = (this.currentElements = this.elements());
    elements[i];
    i++
  ) {
    if (
      this.findByName(elements[i].name).length !== undefined &&
      this.findByName(elements[i].name).length > 1
    ) {
      for (var cnt = 0; cnt < this.findByName(elements[i].name).length; cnt++) {
        this.check(this.findByName(elements[i].name)[cnt]);
      }
    } else {
      this.check(elements[i]);
    }
  }
  return this.valid();
};

$.validator.setDefaults({
  errorPlacement: function (error, element) {
    // var animalId   = getBatchAnimalId(element);
    // var eventIndex = $(element).attr("event_index");
    // var errList    = $(
    //   ".error_list[animal_id=" + animalId + "][event_index=" + eventIndex + "]"
    // );

    // // WTF is jQuery.get()? NOT $ajax.get
    // /* Get the Nth element in the matched element set OR
    //    Get the whole matched element set as a clean array
    // */

    // var errTextArray = $(errList).find("li.errorWrapper label")
    //                         .map(function () {
    //                           return $(this).text();
    //                         }).get();

    // if (errTextArray.indexOf($(error).text()) < 0) {
    //   error.addClass("errorWrapper");
    //   error.appendTo(errList);
    // }
    error.addClass("errorWrapper")
    error.insertAfter( element );
  },
  invalidHandler: function(event, validator) {
    let $form  = $(event.target)
    let formId = $form.attr("id")

    console.debug(`invalidHandler of form #${formId}`)

    $(".waiting").removeClass('waiting');
  },
  wrapper: "li",
  ignore: ":hidden, button"
});

/* Validator method for a unique date across a certain type of event*/
$.validator.addMethod(
  "uniqueDate_pg1",
  function (value, element, param) {
    var date_elems = $.unique($(param));
    var elem_count = date_elems.length;
    var valid_flag = true;

    if (elem_count > 1) {
      var my_date = $(element).val();

      date_elems.each(function (i, elem) {
        if (elem !== element) {
          valid_flag = valid_flag && my_date != $(elem).val();
        }
      });

      return;
    }
    this.optional(element) || valid_flag;
  },
  "This field can't have a duplicate date"
);

/* With a multi-box text area, ensure at least 1 is non-zero */
$.validator.addMethod(
  "require_greaterThan0_fromGroup",
  function (value, element) {
    var validator = this;
    var fields = traverseToBatchField(element, "weight");

    if ($(fields).length <= 1) {
      return (
        Number(validator.elementValue(element)) > 0
      ); /* If this is a single box, just check the value */
    }

    var validOrNot =
      $(fields).filter(function () {
        if (validator.elementValue(this) > 0) {
          return validator.elementValue(this);
        }
      }).length >=
      $(fields).length - 1;

    return validOrNot;
  },
  "Weight must be greater than zero"
);

/* Validator method to require one entry from a group */
$.validator.addMethod(
  "require_One_fromGroup",
  function (value, element) {
    var validator = this;
    var fields = traverseToBatchField(element, "weight");

    if ($(fields).length < 1) {
      return Number(
        validator.elementValue(element)
      ); /* If this is a single box, just check the value */
    }
    var validOrNot =
      $(fields).filter(function () {
        return validator.elementValue(this);
      }).length >= 1;

    return validOrNot || value > 0;
  },
  "Weight is required"
);

/* Validator method to prevent a pending test with a specified date */
$.validator.addMethod(
  "pending_with_date",
  function (value, element, result_tags) {
    var result_text = result_tags[0].children().filter(":selected").text();
    var result_on_text = result_tags[1].val();
    return !(Boolean(result_on_text) && result_text == "Pending");
  },
  "Result can’t be Pending when Date Result Received is indicated"
);

/* Validator method for multiple-box being greater than or equal to zero */
$.validator.addMethod(
  "require_greaterThan0_orBlank_fromGroup",
  function (value, element) {
    var validator      = this;
    var localValidator = this;
    var fields         = traverseToBatchField(element, "weight");

    if ($(fields).length < 1) {
      return (
        validator.elementValue(element) != "0"
      ); /* If this is a single box, just check the value */
    }
    var validOrNot =
      $(fields).filter(function () {
        if (localValidator.elementValue(this) <= 0) {
          return localValidator.elementValue(this);
        }
      }).length <=
      $(fields).length - 1;

    return this.optional(element) || validOrNot;
  },
  "Weight must be greater than zero"
);