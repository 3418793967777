import { Controller } from "@hotwired/stimulus"

import {
  latestMethodology,
  checkNicknameInputForDuplicate,
  hideHerdAnimalFieldsOnEdit } from '../original_easykeeper/breedings'

// Connects to data-controller="kid-fields"
export default class extends Controller {
  connect() {
    // console.debug('kid-fields connected')

    $('select.kid_birth_status', this.element).trigger('change');
    $('input.kid_name_input', this.element).trigger('input');

    console.debug(`latestMethodology() is "${latestMethodology()}"`)

    $(".breeding_kids_conception_type select", this.element).last().val(latestMethodology());

    $(this.element).on('change keyup paste mouseup', '.kid_nickname', checkNicknameInputForDuplicate);

    // Important!
    // If this data-list input isn't present we take it to mean we've already attached a HerdAnimal
    // to the Kid. So we can hide rest of form fields

    if($('.kid_name_input', this.element).length == 0) {
      hideHerdAnimalFieldsOnEdit(this.element);
    }
  }
}
