import { Controller } from "@hotwired/stimulus"

import { searchAnimalNamesForList } from '../original_easykeeper/breedings'

import _ from "../vendor/lodash.custom"

// Connects to data-controller="herd-animal-name-search"
export default class extends Controller {
  connect() {
    console.debug('searchAnimalNamesForList ON with debounce')
    // An event name can be qualified by event namespaces that simplify removing or triggering the event.
    // 'input' is the event.  '.searchAnimalNamesForList' is the namespace
    $(this.element).on('input.searchAnimalNamesForList', _.debounce(searchAnimalNamesForList, 350));
  }

  disconnect() {
    console.debug('searchAnimalNamesForList OFF')
    $(this.element).off('input.searchAnimalNamesForList');
  }
}
