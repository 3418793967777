import 'jquery-ui/ui/widgets/dialog';

import Rails from "@rails/ujs";

const handleConfirm = function(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element)
  }
}

const allowAction = element => {
  const message = element.getAttribute("data-confirm-swal");

  if (!message) {
    return true;
  }

  if (Rails.fire(element, "confirm")) {
    Rails.showConfirmDialog(element, message)
  }
}

const confirmed = (element, result) => {
  if (result) {
    // User clicked confirm button
    element.removeAttribute('data-confirm-swal')
    element.click()
  }
}

Rails.delegate(document, 'a[data-confirm-swal]', 'click', handleConfirm)


Rails.showConfirmDialog = function (link, message, okCallback) {
  let html  = `<div id="dialog-confirm" title="Are you sure?"><p>${message}</p></div>`;

  return $(html).dialog({
    resizable: false,
    modal: true,
    buttons: [
      {
        text: "Yes",
        icons: {
          primary: "ui-icon-check",
        },
        click: function () {
          if(okCallback) {
            okCallback()
          } else {
            let answer = true
            confirmed(link, answer);
            Rails.fire(link, "confirm:complete", [ answer ])
          }

          return $(this).dialog("close");
        },
      },
      {
        text: "Cancel",
        icons: {
          primary: "ui-icon-cancel",
        },
        click: function () {
          return $(this).dialog("close");
        },
      },
    ],
  });
};

Rails.showBeheWarningDialog = function (link, message, okCallback) {
  let html    = `<div id="dialog-confirm" title="Duplicate Event"><p>${message}</p></div>`;

  return $(html).dialog({
    resizable: false,
    modal: true,
    width: 400,
    buttons: [
      {
        text: "Remove Event",
        icons: {
          primary: "ui-icon-check",
        },
        click: function () {
          if(okCallback) {
            okCallback()
          } else {
            let answer = true
            confirmed(link, answer);
            Rails.fire(link, "confirm:complete", [ answer ])
          }
          return $(this).dialog("close");
        },
      },
      {
        text: "Continue Adding This Event",
        icons: {
          primary: "ui-icon-cancel",
        },
        click: function () {
          return $(this).dialog("close");
        },
      },
    ],
  });
};

Rails.showBeheErrorDialog = function (link, message) {
  let html    = `<div id="dialog-confirm" title="Duplicate Event"><p>${message}</p></div>`;

  return $(html).dialog({
    resizable: false,
    modal: true,
    buttons: [
      {
        text: "Close",
        icons: {
          primary: "ui-icon-check",
        },
        click: function () {
          return $(this).dialog("close");
        },
      },
    ],
  });
};

Rails.showBeheDateBoundDialog = function (link, message) {
  let html    = `<div id="dialog-confirm" title="Date Out of Bounds"><p>${message}</p>\n</div>`;

  return $(html).dialog({
    resizable: false,
    modal: true,
    buttons: [
      {
        text: "Close",
        icons: {
          primary: "ui-icon-check",
        },
        click: function () {
          return $(this).dialog("close");
        },
      },
    ],
  });
};
