import { Controller } from "@hotwired/stimulus"
import 'jquery-ui/ui/widgets/autocomplete'
import { delegate, abnegate } from 'jquery-events-to-dom-events'
import { validateDateFieldsFormat } from "../original_easykeeper/inputs"
import { loadingBar } from "../original_easykeeper/dialogs"

// Connects to data-controller="autocomplete"
export default class extends Controller {
  connect() {
    this.delegate_autocompleteclose = delegate("autocompleteclose")
    this.delegate_autocompleteselect = delegate("autocompleteselect", ['event', 'ui'])

    let self = this;
    let $searchField = $(this.element)
    this.$searchField = $searchField

    $searchField
      .on("blur click", function (event) {
        if (event.type === "blur") {
          if ($(this).val().trim() === "") {
            $($(this).data("field")).val("");
          }
        } else if (event.type === "click") {
          this.select();
        }
      })
      .autocomplete({
        source:    $searchField.data("source"),
        minLength: $searchField.data("minLength"),
        select:    function (value, data) {
          self.fillInputs(data)

          // MUST! return false else default select behavior of autocomplete will happen, which
          // is to fill field with option.value but we want option.label
          return false
        },

        // MUST! not return false else default search behavior will not trigger!
        search: function () {
          let spinnerSrc = $('#spinner-gif').attr('src');

          $searchField.attr(
            "style", `background: url('${ spinnerSrc }') white right no-repeat`);
        },

        response: function () {
          $searchField.removeAttr("style");
        },
      });
  }

  fillInputs(data) {
    // Find & fill VISIBLE search field
    // put selection's label into search field input
    this.$searchField.val(data.item.label);

    // Find & fill HIDDEN input and set its value to selection value
    // hidden field is called 'registered_name' but we put an ID in it...!
    //
    this.$searchField
      .closest(".input")
      .find("input[type=hidden]")
      .val(data.item.value);
  }

  disconnect () {
    abnegate('autocompleteclose', this.delegate_autocompleteclose)
    abnegate('autocompleteselect', this.delegate_autocompleteselect)

    if(this.$searchField.hasClass('ui-autocomplete')) {
      this.$searchField.autocomplete("destroy")
    }
  }

  // Triggered from autocomplete_input.rb
  // action: '$autocompleteclose@document->autocomplete#conflictCheck'
  // $autocompleteclose is a delegated jquery UI event to DOM event
  conflictCheck(event) {
    console.debug('autocomplete#conflictCheck')

    let eventConflictsCheckUrl    = event.params.eventConflictsCheckUrl
    let reminderConflictsCheckUrl = event.params.reminderConflictsCheckUrl

    if( eventConflictsCheckUrl ) {
      this.checkForEventConflicts(event, eventConflictsCheckUrl)

    } else if(reminderConflictsCheckUrl) {
      this.checkForReminderConflicts(event, reminderConflictsCheckUrl)
    }
  }

  checkForReminderConflicts(event, url) {
    console.debug('autocomplete#checkForReminderConflicts')

    $.ajax({
      url: url,
      dataType: "script",
      data: {
        health_event_activity_id: $('#herd_animal_treatment_reminder_health_event_activity_id').val(),
        category: $('#category').val(),
        occurred_on: $('#health_event_occurred_on').val()
      }
    });
  }

  checkForEventConflicts(event, url) {
    console.debug('autocomplete#checkForEventConflicts')

    let $form = $(event.target.closest('form'))
    let health_event_category = $('#category').val()
    let health_event_occurred_on_value = $('#health_event_occurred_on').val()

    if(validateDateFieldsFormat($form) && health_event_occurred_on_value) {
      $.ajax({
        url: url,
        dataType: "script",
        data: {
          health_event_activity_id: $('#health_event_health_event_activity_id').val(),
          category: health_event_category,
          occurred_on: health_event_occurred_on_value
        }
      });
    }
  }

  addAncestor(event) {
    console.debug('autocomplete#addAncestor')
    let ui = event.detail.ui;

    this.fillInputs(ui)

    let $selectedField = $('.autocomplete.herd_animal_registered_name #herd_animal_registered_name');
    let $selectedFieldValue = $selectedField.val()

    // if the user selected an animal, search for it
    if($selectedFieldValue != '') {
      window.fillDialog({html: loadingBar()});

      let url = `/herd_animals/${$selectedFieldValue}`;

      $.ajax({
        url: url,
        type: 'PUT',
        dataType: "script",
        data: {
          ancestor_type: event.params.ancestorType,
          gender:        event.params.gender,
          key:           $("#ancestorKey").val(),
          manual_add:    0,
          pedigree:      true,
          pedigree_key:  event.params.pedigreeKey,
          return_to:     event.params.returnTo
        }
      });
    }
  }
}
