import { Controller } from "@hotwired/stimulus"

import { findMaxBCSScale } from "../original_easykeeper/batch_entry"

// Connects to data-controller="bcs-scale"
export default class extends Controller {
  connect() {
    // '.bcs_scale_select'
    /* BCS Scale Validations */
    // console.debug('bcs-scale connected')
  }

  change() {
    console.debug('bcs-scale#change()')

    $(".bulkHealthBCS").each( function() {
      console.debug(this)

      $(this).each( function() {
        console.debug(this)

        $(this).rules("add", {
          number: true,
          min: 1,
          max: function(element) {
            return findMaxBCSScale(element);
          },
          messages: {
            number: "Body Condition Score must be a number",
            min: "Body Condition Score must be greater than or equal to 1",
            max: function(index, element) {
              return "Body Condition Score must be less than or equal to " + findMaxBCSScale(element);
            },
          }
        });
      });

      $(this).valid();
    });
  }
}
