import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter-animals"
export default class extends Controller {
  connect() {

  }

  refine(event) {
    let msg = $(event.currentTarget).data('flash')

    if(confirm(msg)) {
      window.App.openOverlay()
      $('#filter-animals-form button').addClass('waiting');

      this.element.submit()
    } else {
      $('#filter-animals-form button').removeClass('waiting');
    }
  }
}
