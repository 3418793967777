import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="gender-select"
export default class extends Controller {
  connect() {
    this.change()
  }

  change() {
    let $element = $(this.element)
    let $form = $element.closest("form");

    if ($element.val() == "f") {
      $form.find(".female").removeClass("hidden");
      $form.find(".male").addClass("hidden");
      $form.find("#herd_animal_castrated").attr("checked", false);
    } else {
      $form.find(".male").removeClass("hidden");
      $form.find(".female").addClass("hidden");
    }
  }
}
