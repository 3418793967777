import { Controller } from "@hotwired/stimulus"
import { setDefaultPrimaryRegistration, setPrimaryRegistration } from "../original_easykeeper/inputs"

// Connects to data-controller="herd-animal-registrations"
export default class extends Controller {
  connect() {
  }

  add(event) {
    let $this                   = $(event.target)
    let $registrationIdsWrapper = $this.closest(".registration_ids")
    let $error                  = $registrationIdsWrapper.find(".add_registration_error")
    let $addRegistrationFields  = $registrationIdsWrapper.find(".add_registration_fields")
    let $newId                  = $addRegistrationFields.find("#new_herd_animal_registration_raw_registration_number")
                                                        .val()

    // Ensure registration id is unique.
    if ($newId != "") {
      $registrationIdsWrapper.find(".controls .raw_registration_number input").each(function () {
        if ($this.val() == $newId) {
          $error.html("That registration id is already associated with this animal.")
                .removeClass("hidden")
        } else {
          $error.html("").addClass("hidden")
        }
      });
    }

    let $form          = $registrationIdsWrapper.closest("form")
    let $pedigreeField = $this.closest("form").find("input#pedigree")

    let url            = `${$form.attr("action")}/insert_registration`
    let form_id        = $form.attr("id")

    // Prepare the data to be serialized
    let formData = $addRegistrationFields.find("input, select").serializeArray();

    formData.push({ name: 'form_id', value: form_id });

    if ($pedigreeField.length) {
      let pedigreeValue  = $pedigreeField.val()
      formData.push({ name: $pedigreeField.attr("name"), value: pedigreeValue });
    }

    $.ajax({
      url:  url,
      type: "POST",
      data: formData,
      complete: () => {
        $('.waiting').removeClass('waiting')

        let table = $registrationIdsWrapper.find('table')
        setDefaultPrimaryRegistration(table)
      },
      error: (jqXHR, textStatus, errorThrown) => {
        console.error('Error occurred: ', textStatus, errorThrown);
      }
    });
  }

  // Add Herd Animal Registration - table row "[Delete]" link (basically nested form style delete)
  delete(event) {
    var $link = $(event.target);
    var $tr   = $link.closest("tr");

    // set destroy flag to true for ActiveRecord
    $link.closest("td").find(".destroy").val(1);

    // deleted registration can't be primary, then hide it
    $tr.find(".primary_registration").val("false");
    $tr.addClass("hidden");

    // choose another reg as primary
    setDefaultPrimaryRegistration($link.closest("table"));
  }

  // Add Herd Animal Registration - table row "Primary" radio input
  changePrimary(event) {
    setPrimaryRegistration(event.target)
  }
}
