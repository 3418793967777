import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="party-checkbox"
export default class extends Controller {
  connect() {

  }

  setDestroy() {
    let $checkbox = $(this.element)

    $checkbox.closest('.party_checkbox')
             .find('input.destroy-field')
             .val(this.checked ? 'false' : 'true');
  }
}
