import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="other-option"
export default class extends Controller {
  static values = {
    otherWrapperId: String
  }

  connect() {

  }

  toggleOtherField() {
    console.debug(`toggleOtherField on change '${this.otherWrapperIdValue}'`)

    let $otherField = $(`#${this.otherWrapperIdValue}`)

    if (this.element.value == '-1') {
      $otherField.removeClass('hidden');
    } else {
      $otherField.addClass('hidden');

      // If we switch to a select option selection clear out the free hand input
      $otherField.find('input').val('');
    }

    console.debug(`'${this.otherWrapperIdValue}': ${$otherField.val()}`)
  }
}
