
jQuery.fn.max = function () {
  return Math.max.apply(Math, jQuery.makeArray(this));
};

jQuery.fn.exists = function () {
  return this.length !== 0;
};

Array.max = function (array) {
  return Math.max.apply(Math, array);
};

Array.min = function (array) {
  return Math.min.apply(Math, array);
};

Array.prototype.show = function () {
  this.each(function (e) {
    if (e != null) {
      e.removeClass("hidden");
    }
  });
};

Array.prototype.hide = function () {
  this.each(function (e) {
    if (e != null) {
      e.addClass("hidden");
    }
  });
};

Date.prototype.toMMDDYYYYString = function () {
  return isNaN(this)
    ? "NaN"
    : [
        this.getMonth() > 8 ? this.getMonth() + 1 : "0" + (this.getMonth() + 1),
        this.getDate() > 9 ? this.getDate() : "0" + this.getDate(),
        this.getFullYear(),
      ].join("/");
};

// function set_date_to_today(id) {
//   var today = new Date();
//   $("#" + id + "_2i").val(today.getMonth() + 1); //month
//   $("#" + id + "_3i").val(today.getDate()); //day
//   $("#" + id + "_1i").val(today.getFullYear()); //year
// }

// function roundNumber(num, dec) {
//   return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
// }

function setCookie(name, value, days) {
  var expires;

  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  } else expires = "";
  document.cookie = name + "=" + value + expires + "; path=/";
}

window.setCookie = setCookie;

// function getCookie(name) {
//   var nameEQ = name + "=";
//   var ca = document.cookie.split(";");
//   for (var i = 0; i < ca.length; i++) {
//     var c = ca[i];
//     while (c.charAt(0) == " ") c = c.substring(1, c.length);
//     if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
//   }
//   return null;
// }

// function deleteCookie(name) {
//   setCookie(name, "", -1);
// }

/* REGISTRATION OPTIONS */


function disableButtonOnSubmit() {
  $('form').submit(function() {
    // Without the delay, the submit button parameters are not sent in the request.
    var $submits = $('input[type=submit], button', this);
    setInterval(function() { $submits.attr('disabled', 'disabled'); }, 100);
  });
}

// Update milk weight totals.

function parseIntegerField($input) {
  var value = $input.val();

  if (value == "") {
    $input.removeClass("field_with_errors");
    return null;
  }

  if (value.match(/^\d*\.?0*$/) == null) {
    $input.addClass("field_with_errors");
    return NaN;
  }

  var i = parseInt(value);

  if (isNaN(i)) {
    $input.addClass("field_with_errors");
    return NaN;
  } else {
    $input.removeClass("field_with_errors");
    $input.val(i.toString());
    return i;
  }
}

function parseFloatField($input) {
  var value = $input.val();

  if (value == "" || value == undefined) {
    $input.removeClass("field_with_errors");
    return null;
  }

  if (value.match(/^\d*\.?\d*$/) == null) {
    $input.addClass("field_with_errors");
    return NaN;
  }

  var f = parseFloat(value);

  if (isNaN(f)) {
    $input.addClass("field_with_errors");
    return NaN;
  } else {
    $input.removeClass("field_with_errors");
    return f;
  }
}

function updateDailyTotal(parent) {
  console.debug('updateDailyTotal(parent)')

  let $parent = $(parent);

  if ($parent.find("input.am_total_pounds").length > 0) {
    let am_pounds = parseIntegerField($parent.find("input.am_total_pounds")) || 0;
    let am_ounces = parseIntegerField($parent.find("input.am_total_ounces")) || 0;
    let pm_pounds = parseIntegerField($parent.find("input.pm_total_pounds")) || 0;
    let pm_ounces = parseIntegerField($parent.find("input.pm_total_ounces")) || 0;

    // Handle any invalid value.
    if (
      isNaN(am_pounds) ||
      isNaN(am_ounces) ||
      isNaN(pm_pounds) ||
      isNaN(pm_ounces)
    ) {
      return;
    }

    // Handle no individual milking values.
    let $total_pounds_input = $parent.find("input.daily_total_pounds");
    let $total_ounces_input = $parent.find("input.daily_total_ounces");
    let $total_input       = $parent.find("input.daily_total_pounds, input.daily_total_ounces");

    if (am_pounds == null &&
        am_ounces == null &&
        pm_pounds == null &&
        pm_ounces == null
    ) {
      parseIntegerField($total_pounds_input);
      parseIntegerField($total_ounces_input);

      $total_input.removeAttr("disabled");

      return;
    }

    // Handle individual milking values.
    if (am_pounds == null) { am_pounds = 0; }
    if (am_ounces == null) { am_ounces = 0; }
    if (pm_pounds == null) { pm_pounds = 0; }
    if (pm_ounces == null) { pm_ounces = 0; }

    let daily_pounds_and_ounces = convert_to_pounds_and_ounces(am_pounds + pm_pounds,
                                                               am_ounces + pm_ounces);

    if(daily_pounds_and_ounces[0] == 0) {
      // blank out field rather than show tons of zeroes
      $total_pounds_input.val('');
    } else {
      $total_pounds_input.val(daily_pounds_and_ounces[0]);
    }

    if(daily_pounds_and_ounces[1] == 0) {
      // blank out field rather than show tons of zeroes
      $total_ounces_input.val('');
    } else {
      $total_ounces_input.val(daily_pounds_and_ounces[1]);
    }

    $total_input.prop("disabled", true);

  } else {

    let am = parseFloatField($parent.find("input.am_total_pounds_decimal, input.am_total")) || 0;
    let pm = parseFloatField($parent.find("input.pm_total_pounds_decimal, input.pm_total")) || 0;

    // Handle any invalid value.
    if (isNaN(am) || isNaN(pm)) {
      return;
    }

    // Handle no individual milking values.
    let $total_input = $parent.find("input.daily_total_pounds_decimal, input.daily_total");

    if (am == null && pm == null) {
      let total = parseFloatField($total_input);

      if (!isNaN(total) && total != null) {
        $total_input.val(roundNumbersToDecimals(total, 3));
      }

      $total_input.removeAttr("disabled");

      return;
    }

    // Handle individual milking values.
    if (am == null) { am = 0; }
    if (pm == null) { pm = 0; }

    let total_value = roundNumbersToDecimals(am + pm, 3)

    console.log(`total_value == 0.000 ? ${total_value}`)

    if(total_value == 0.000) {
      // blank out field rather than show tons of zeroes
      $total_input.val('');
    } else {
      $total_input.val(total_value);
    }

    $total_input.prop("disabled", true);
  }
}

window.updateDailyTotal = updateDailyTotal;

function convert_to_pounds_and_ounces(pounds_summed, ounces_summed) {
  let pounds = 0;
  let ounces = 0;
  let pounds_from_ounces = 0;

  if (ounces_summed > 15) {
    pounds_from_ounces = parseInt(ounces_summed / 16.0);
    pounds = pounds_summed + pounds_from_ounces;
    ounces = ounces_summed - pounds_from_ounces * 16.0;
  } else {
    pounds = pounds_summed;
    ounces = ounces_summed;
  }
  return [pounds, ounces];
}

function roundNumbersToDecimals(number, decimals) {
  number = number * Math.pow(10, decimals);
  number = Math.round(number);
  number = number / Math.pow(10, decimals);

  return number.toFixed(decimals);
}

function compareDatesReversed(a, b) {
  return (
    Date.parse($(a).find("td:first").text()) -
    Date.parse($(b).find("td:first").text())
  );
}

window.compareDatesReversed = compareDatesReversed;

function compareDates(a, b) {
  return (
    Date.parse($(b).find("td:first").text()) -
    Date.parse($(a).find("td:first").text())
  );
}

window.compareDates = compareDates;

export function monthDiff(startDate, endDate) {
  let months =
    endDate.getMonth() -
    startDate.getMonth() +
    (endDate.getFullYear() - startDate.getFullYear()) * 12;

  if (endDate.getDate() < startDate.getDate()) {
    months = months - 1;
  }

  if (months < 0) {
    months = 0;
  }

  return months;
}

// Add to or create error box at top of selected parent element instead of alert dialog
// if container element is not found defaults to alert() use: errorMessage('Missing field','form');
function errorMessage(message, container) {
  // if a selector is provided resolve it to a jQ element
  if (typeof container == "string") {
    container = $(container);
  }

  // Look for existing error box
  if (container.exists()) {
    let errbox = container.find(".error_explanation").first();

    if (errbox.exists() == false) {
      container.prepend(
        '<div class="error_explanation" id="error_explanation"></div>'
      );
    }

    container
      .find(".error_explanation")
      .first()
      .append(`<p>${message}</p>`);
  } else {
    alert(message);
  }
}

// Remove an error message from an error box and remove the box if then empty
function rescindErrorMessage(message, container) {
  // if a selector is provided resolve it to a jQ element
  if (typeof container == "string") {
    container = $(container);
  }

  // Look for existing error box
  if (container.exists()) {
    let errbox = container.find(".error_explanation").first();

    if (errbox.exists()) {
      let contents = errbox.html();
      contents = contents.replace("<p>" + message + "</p>", "");

      if (contents == "") {
        errbox.remove();
      } else {
        errbox.html(contents);
      }
    }
  }
}

// Places a red box around a form field and records an error message
export function formFieldNG(fieldEl, errMssg) {
  let doMssg = false;
  let condiv = fieldEl.closest("div.input");

  if (condiv.exists()) {
    if (condiv.hasClass("field_with_errors") == false) {
      condiv.addClass("field_with_errors");
      doMssg = true;
    }
  } else {
    if (fieldEl.hasClass("field_with_errors") == false) {
      fieldEl.addClass("field_with_errors");
      doMssg = true;
    }
  }

  if (doMssg) {
    fieldEl.attr("data-errmsg", errMssg);
    errorMessage(errMssg, fieldEl.closest("form"));
  }
}

// Removes the red field around a form input and attempts to remove an error message
// from the form error box
export function formFieldOK(fieldEl) {
  let doMssg = false;
  let condiv = fieldEl.closest("div.input");

  if (condiv.exists()) {
    if (condiv.hasClass("field_with_errors")) {
      condiv.removeClass("field_with_errors");
      doMssg = true;
    }
  } else {
    if (fieldEl.hasClass("field_with_errors")) {
      fieldEl.removeClass("field_with_errors");
      doMssg = true;
    }
  }

  if (doMssg) {
    rescindErrorMessage(fieldEl.attr("data-errmsg"), fieldEl.closest("form"));
    fieldEl.removeAttr("data-errmsg");
  }
}


// Support for browsers that don't handle startsWith - fixes bug on pedigree entry in IE
if (!String.prototype.startsWith) {
  String.prototype.startsWith = function (searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}