import validate from "jquery-validation";
import 'jquery-ui/ui/widgets/datepicker';
import { decorateButtons } from './inputs'

function filterIsActive($filter_tag) {
  let tag_type = $filter_tag.attr("type");
  let result   = false;

  if (tag_type == undefined) {
    tag_type = $filter_tag.prop("type");
  }

  if (tag_type == "select-one") {
    let tag_text = $filter_tag.find(":selected").text();

    result = tag_text != "--- Select ---" && tag_text != "All";

  } else if (tag_type == "text")
    result = $filter_tag.text() != "" || $filter_tag.val() != "";

  else
    result = false;

  console.debug(`filterIsActive(#${$filter_tag[0].id}) --> result: ${result}`)
  console.debug("")

  return result
}

function markActiveFilter($filter_tag) {
  console.debug(`markActiveFilter('${$filter_tag[0]}')  filter_tag id: #${$filter_tag.attr('id')} name: ${$filter_tag[0].name}`)
  console.dir($filter_tag[0])

  $filter_tag.toggleClass("activeFilter", filterIsActive($filter_tag));
}

window.markActiveFilter = markActiveFilter;

export function checkForBlankCategory() {
  console.debug('checkForBlankCategory()')

  decorateButtons()

  let categoryIsBlank = false;

  $(".category").each(function (i, el) {
    if ($(el).val() == "")
      categoryIsBlank = true;
  });

  if(categoryIsBlank) {
    $("#continue_button").addClass('hidden')
                         .button('disable');
    $(".add_health_event_template_fields").addClass('hidden');

  } else {
    $("#continue_button").removeClass('hidden')
                         .button('enable');
    $(".add_health_event_template_fields").removeClass('hidden');
  }
}

window.checkForBlankCategory = checkForBlankCategory;

export function clearFormErrors() {
  console.debug('clearFormErrors()')

  $(".error_explanation").remove();  // jQuery/Rails validation errors message
}

window.clearFormErrors = clearFormErrors;

// What does this do?
// When there are multiple health event category selects on the page
// like for bulk_data health events.  It makes sure prior selections are
// re-selectable from other selects
export function ensureUniqueCategories(uniqueCategories = ["weight", "bcs"]) {
  console.debug('ensureUniqueCategories(uniqueCategories = ["weight", "bcs"])')

  /* This is basically $('.category').val(), but type issues make it more verbose :/ */
  var pageCategories = $.makeArray($(".category")).map(function (elem) {
    return $(elem).val();
  });

  uniqueCategories.forEach(function (category) {
    if (pageCategories.indexOf(category) != -1) {
      hideCategory(category);
    } else {
      /* Important for bringing categories back */
      showCategory(category);
    }
  });
}

window.ensureUniqueCategories = ensureUniqueCategories;

function hideCategory(category) {
  console.debug('hideCategory(category)')

  $(".category").each(function () {
    if ($(this).val() != category) $(getOption($(this), category)).addClass("hidden");
  });
}

function showCategory(category) {
  console.debug('showCategory(category)')

  $(".category").each(function () {
    if ($(this).val() != category) $(getOption($(this), category)).removeClass("hidden");
  });
}

function getOption(selector, optVal) {
  console.debug('getOption(selector, optVal)')

  var opts = $.makeArray(selector.find("option"));

  return opts.filter(function (elem) {
    return $(elem).val() == optVal;
  })[0];
}


// Should only be used on Step 1 of Wizard (also called in event_categories controller.. necessary?)
// 1. Create Health Event Templates
// called in bulk_data/health_event_fields.js.erb
// TRIGGER: when Event Category selection happens
export function healthEventTemplateFieldsValidations(fieldsWrapperSelector) {

  // fieldsWrapperSelector is of format
  // "#healthTemplateFields_<%= form_uid %>"
  //
  let $fieldsWrapper = $(fieldsWrapperSelector)

  console.log(`healthEventTemplateFieldsValidations(${fieldsWrapperSelector})`)

  $fieldsWrapper
    .find(".healthEventActivityIdField, .occurredOnField")
    .each(function (index, elem) {
      $(elem).rules("add", {
        required: true,
        messages: {
          required: function (index_inner, elem_inner) {
            var control = $(elem_inner).closest(".controls");
            var label   = control.siblings(".control-label").text();

            label = label.replace(":", "").replace("*", "");

            return label + " can't be blank";
          },
        },
      });
    });

  /* Weight */
  $fieldsWrapper
    .find(".weightField")
    .each(function (index, elem) {
      $(elem).rules("add", {
        required: true,
        number: true,
        digits: $(elem).hasClass("oz") || $(elem).hasClass("lb"),
        require_greaterThan0_orBlank_fromGroup: true,
        messages: {
          require_greaterThan0_orBlank_fromGroup:
            "Weight must be greater than zero",
          number: "Weight must be a number",
          digits: "Weight value must be a whole number",
        },
      });
    });

  $fieldsWrapper
    .find(".healthEventResultIdField[category=test]")
    .each(function (index, elem) {
      var eventIndex = $(elem).attr("event_index");
      $(elem).rules("add", {
        pending_with_date: [
          $fieldsWrapper.find(
            "select.healthEventResultIdField[event_index=" + eventIndex + "]"
          ),
          $fieldsWrapper.find("input.resultOnField[event_index=" + eventIndex + "]"),
        ],
        messages: {
          pending_with_date:
            "Result can’t be Pending when Date Result Received is indicated",
        },
      });
    });

  /* Dates */
  $fieldsWrapper
    .find(".occurredOnField, resultOnField")
    .each(function (index, elem) {
      $(elem).rules("add", {
        date: true,
        messages: {
          date: "Please format date fields as MM/DD/YYYY",
        },
      });
    });
}

window.healthEventTemplateFieldsValidations = healthEventTemplateFieldsValidations;

function setValidations(form) {
  $(form)
    .find(".batchInput")
    .each(function (index, elem) {
      var field_name = $(elem).attr("field_name");
      var eventIndex = $(elem).attr("event_index");
      var category   = $(elem).attr("category");

      if (field_name == "weight") {
        $(elem).rules("add", {
          number: true,
          digits: $(elem).hasClass("lb") || $(elem).hasClass("oz"),
          require_greaterThan0_fromGroup: true,
          require_One_fromGroup: true,
          messages: {
            require_One_fromGroup: "Weight can't be blank",
            number: "Weight must be a number",
            digits: "Weight value must be a whole number",
            require_greaterThan0_fromGroup: "Weight must be greater than 0",
          },
        });
      }

      if (category == "bcs" && field_name == "health_event_method_id") {
        $(elem).rules("add", {
          required: true,
          messages: {
            required: "Scale can't be blank",
          },
        });
      }

      if (category == "bcs" && field_name == "body_condition_score") {
        $(elem).rules("add", {
          required: true,
          number: true,
          min: 1,
          max: function (element) {
            return findMaxBCSScale(element);
          },
          messages: {
            required: "Body Condition Score can't be blank",
            number: "Body Condition Score must be a number",
            min: "Body Condition Score must be greater than or equal to 1",
            max: function (index, element) {
              return (
                "Body Condition Score must be less than or equal to " +
                findMaxBCSScale(element)
              );
            },
          },
        });
      }

      if (category == "test" && field_name == "result_on") {
        $(elem).rules("add", {
          date: true,
          messages: {
            date: "Please format date fields as MM/DD/YYYY",
          },
        });
      }

      if (category == "test" && field_name == "health_event_result_id") {
        $(elem).rules("add", {
          required: true,
          pending_with_date: [
            $(form).find(
              "select.healthEventResultIdField[event_index=" + eventIndex + "]"
            ),
            $(form).find("input.resultOnField[event_index=" + eventIndex + "]"),
          ],
          messages: {
            required: "Result can't be blank",
            pending_with_date:
              "Result can’t be Pending when Date Result Received is indicated",
          },
        });
      }

      if (field_name == "occurred_on") {
        $(elem).rules("add", {
          date: true,
          messages: {
            date: "Date can't be blank",
          },
        });
      }
    });
}

window.setValidations = setValidations;

export function eagerValidations() {
  console.debug('eagerValidations()')

  // $("select.batchInput").on("blur change", function (e) {
  //   $(e.currentTarget).valid();
  // });

  $("form :input").not('button').not("[type=hidden]").on("blur change", function (e) {
    $(e.target).valid();
  });

  // multi-field
  $(".weightField").on("keyup", function (event) {
    traverseToBatchField($(event.currentTarget), "weight").map(function(index, elem) {
      let id      = $(elem).attr("id");
      let errorElem = $(".error[for=" + id + "]");

      $(errorElem).parent().remove();
      $(elem).valid();
    });
  });

  // synchronize test result fields
  $(".healthEventResultIdField[category=test]").change(function (e) {
    let elem = e.currentTarget;
    let resultOnField = traverseToBatchField(elem, "result_on");

    $(resultOnField).valid();
  });
}
window.eagerValidations = eagerValidations;

// Called from /bulk_data/bulk_apply_health_events.html.haml
function preValidateFields(form) {
  let inputs = $(form).find("input, select, textarea");

  inputs.each(function (index, elem) {
    $(elem).valid();
  });
}

window.preValidateFields = preValidateFields;



/******************************************/
/********* Form Toggle Functions **********/
/******************************************/
function collapseExpandTemplate(template, toggleButton, expandOrCollapse) {
  // var expandOrCollapse = template.find('.batchTemplateFields').hasClass('hidden');
  $(template)
    .find(".batchTemplateFields")
    .toggleClass("hidden");
}

window.collapseExpandTemplate = collapseExpandTemplate;

/* Toggle visibility of a form */
function toggleEvent(subform, addOrRemove, collapseFields) {
  console.debug(`toggleEvent(${subform}, ${addOrRemove}, ${collapseFields})`)

  collapseFields =
    typeof collapseFields !== "undefined" ? collapseFields : true;

  var elem = subform.find(".remove-health-event-template");
  var icon = $(elem).find("i.fa");

  toggleLinkState(icon, addOrRemove);

  subform.find(".bulk_event_subform_select")
         .prop("checked", addOrRemove);

  subform.find(".bulkEventTitle")
         .toggleClass("removed_bulk_event_view", !addOrRemove);


  if (collapseFields) {
    subform.find(".batchEventFields, .batchTemplateFields")
           .toggle(addOrRemove);
  }

  let subformItems = subform.find(':input').not('button')

  if(addOrRemove) {
    subformItems.button('enable')
  } else {
    subformItems.button('disable')
  }

  subform.find(".error_list").toggle(addOrRemove);
  subform.find(".health-event-reminders").toggle(addOrRemove);
}

function toggleSubform(subform) {
  /* Get state of form */
  var icon = $(subform).find("i.fa");
  var addOrRemove =
    $(icon).hasClass("fa-plus") || $(icon).hasClass("fa-caret-down");

  /* Toggle visibility of form */
  toggleEvent(subform, addOrRemove);

  $("#reminders_" + $(subform).attr("event_id")).toggle(addOrRemove);
}

window.toggleSubform = toggleSubform;

function updateSelectedAnimalCount() {
  let numAnimals = $(".bulk-animal-selection-checkbox").filter(":checked").length;
  let msg = `${numAnimals} animals are selected`

  $("#selectedAnimalCount").text(msg);

  return numAnimals;
}

window.updateSelectedAnimalCount = updateSelectedAnimalCount;

function handleBulkSaveButtonState(selectedAnimalCount) {
  if(selectedAnimalCount > 0) {
    $("form.form-for-bulk-health-events button[type=submit]").button("enable")
  } else {
    $("form.form-for-bulk-health-events button[type=submit]").button("disable")
  }
}

window.handleBulkSaveButtonState = handleBulkSaveButtonState;

/* Completely toggle visibility of a form(no link to add back) */
function hardToggleEvent(subform, addOrRemove) {
  subform
    .find(".healthEventFields")
    .toggle(addOrRemove);

  subform
    .find(".bulkEventTitle")
    .toggleClass("removed_bulk_event_view", !addOrRemove);

  subform
    .find(".error_list")
    .toggle(addOrRemove);

  subform
    .find(".health-event-reminders")
    .toggle(addOrRemove);

  subform
    .find(".bulkHealthToggle")
    .toggle(addOrRemove);
}

window.hardToggleEvent = hardToggleEvent;

/* Toggle styling of form toggle links */
function toggleLinkState(icon, addOrRemove) {
  $(icon).toggleClass("fa-minus", addOrRemove);
  $(icon).toggleClass("fa-plus", !addOrRemove);

  var title_text = addOrRemove ? "Remove" : "Restore";

  $(icon).attr("title", title_text);
}

window.toggleLinkState = toggleLinkState;

/* Toggle styling of form toggle links */
function toggleCollapseExpandLinkState(icon, addOrRemove) {
  $(icon).toggleClass("fa-caret-up", addOrRemove);
  $(icon).toggleClass("fa-caret-down", !addOrRemove);

  var title_text = addOrRemove ? "Close" : "View";

  $(icon).attr("title", title_text);
}

window.toggleCollapseExpandLinkState = toggleCollapseExpandLinkState;

/* Toggle visibility of a template for all animals */
var confirm_remove_message =
  "Removing this template will remove the health event from all selected animals. Are you sure you want to remove this health event template?";

var confirm_restore_message =
  "Restoring this event template will also restore any information previously added for this event to the currently selected animals.";

function toggleEntireTemplate(template) {
  /* Get state of forms */
  var rm_link = $(template).find(".remove-health-event-template");
  var icon = $(rm_link).find("i.fa");
  var addOrRemove = $(icon).hasClass("fa-plus");
  var event_index = $(template).attr("event_index");

  /* Hide fields */
  var fieldsToToggle = $(
    ".batchEventForm:not(.batchShowTemplate)[event_index=" + event_index + "]"
  );
  toggleEvent($(template.closest(".batchEventForm")), addOrRemove, false);
  hardToggleEvent(fieldsToToggle, addOrRemove);

  /* Toggle Caret for use on possible future restore/remove */
  var expandIcon = $(template).closest(".batchEventForm").find(".fa-caret-up");
  toggleCollapseExpandLinkState(expandIcon);

  /* Set confirm text as appropriate */
  var confirm_text = addOrRemove
    ? confirm_remove_message
    : confirm_restore_message;
  $(rm_link).attr("data-confirm", confirm_text);
}

window.toggleEntireTemplate = toggleEntireTemplate;

/******************************************/
/************ Helper functions ************/
/******************************************/

/* Get event id of an element */
// function getEventId(elem) {
//     var my_id = $(elem).attr('id');
//     return my_id.split('_')[3];
// }

/* Get the selected max BCS scale setting  */
export function findMaxBCSScale(score_field) {
  console.debug(`findMaxBCSScale(score_field)`)

  var scaleField = traverseToBatchField(
    score_field,
    "health_event_method_id"
  ).filter("select");

  var selectedScale = scaleField.find(":selected");
  var scaleText     = selectedScale.text();

  // 1-5 or 1-9 for example for grab 5 or 9
  var maxScale = Number(scaleText.split("-")[1]);

  if (selectedScale.val() == "" || maxScale == 0)
      maxScale = Infinity;

  return maxScale;
}

function initReminders(animal_id) {
  console.debug(`initReminders(animal_id) -- animal_id: ${animal_id}`)

  let reminders = $(":checkbox.bulkHealthReminders");

  reminders = reminders.filter(function (index, elem) {
    return $(elem).attr("animal_id") == animal_id;
  });

  // Acount for 'select all reminders' cbox
  reminders.each(function () {
    this.checked = $("#selectAllReminders").prop("checked");
  });

  let animal_form = $(".animal-template-events-list[animal_id=" + animal_id + "]");
  let subforms    = animal_form.find(".batchEventForm");

  subforms.each(function (index, form) {
    let event_index = $(form).attr("event_index")

    let $template_remove_link = $(`#health_event_template_remove_link_${event_index}`)
                                  .find(".bulkHealthToggle");

    if ($template_remove_link.attr("title") == "Restore") {
      hardToggleEvent($(form), false);
    }
  });

  subforms
    .find(".movingPlaceholderInput")
    .each(function (input_index, input_elem) {
      $(input_elem).attr("value", this.value);
    });
}

window.findMaxBCSScale = findMaxBCSScale;
window.initReminders   = initReminders;

// addBatchForm
// Takes the contents of a links data-field and injects it into nested form
// addLink - is the 'Add another health event template' link (.add_health_event_template_fields)'
//
function addBatchForm(addLink) {
  if( $(".health-event-form.vtable").length >= window.App.maxHealthTemplateForms ) {
    alert('Max number of health event templates reached')
    return
  }

  var time, regexp;

  time   = new Date().getTime()

  // We're going to find all references to the DB id and replace it with a timestamp 'ticks'
  // in the partial (html) saved to the data-fields attr of the link
  //
  let id = $(addLink).data('id')
  regexp = new RegExp(id, 'g')

  var html = $(addLink).data('fields').replace(regexp, time)

  $(addLink).before(html)

  window.ensureUniqueCategories();
  window.checkForBlankCategory();
}

window.addBatchForm = addBatchForm;

$(".add_health_event_template_fields").click(function () {
  $("#continue_button").addClass("hidden");
});

/******************************************/
/*********** Category-Specific ************/
/******************************************/

function getBatchAnimalId(elem) {
  var animalForm = $(elem).closest(".animal-template-events-list");
  var animalId   = $(animalForm).attr("animal_id");

  return (
    animalId || "template"
  ); /* Defaults to 'template' to account for behe pg1 not having a animal-template-events-list */
}

window.getBatchAnimalId = getBatchAnimalId;

// function selectBatchField(animalId, eventIndex, eventName) {
//     var animalIdField = $('.herdAnimalIdField[value=' + animalId + '][event_index=' + eventIndex + ']');
//     var eventSubform = animalIdField.parents('.batchEventForm');
//     var fieldClass = batchFieldClass(eventName);
//     var fieldElem = eventSubform.find('.' + fieldClass);
//     return fieldElem;
// }

function traverseToBatchField(elem, eventName) {
  var batchForm = $(elem).closest(".batchEventForm");
  return $(batchForm)
    .find("input, select, textarea")
    .filter("." + batchFieldClass(eventName));
}

window.traverseToBatchField = traverseToBatchField;

// function batchInputs(form, eventIndex) {
//     eventIndex = eventIndex || $(form).attr('event_index');
//     return $(form).find('input, select, textarea').filter('[event_index=' + eventIndex + ']');
// }

// Copied/modified from https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case
// Thanks to @CMS from SO :)
function batchFieldClass(fieldName) {
  var camelName = fieldName
    .replace(/(?:^\w|[A-Z]|_\w)/g, function (letter, index) {
      return index == 0 ? letter.toLowerCase() : letter.toUpperCase();
    })
    .replace(/_+/g, "");
  return camelName + "Field";
}
